import { dedupe } from "features/customer-drill-down/equipment/useGetData"
import { selectAutomationsByDwellingId, useAutomationsStore } from "features/customer-drill-down/equipment/store"
import { useParams } from "react-router-dom"
import useCurrentDwellingContents from "./useCurrentDwellingContents"

export default () => {
  const contents = useCurrentDwellingContents()
  const { dwellingId } = useParams()
  const automations = useAutomationsStore(selectAutomationsByDwellingId(parseInt(dwellingId || '')))

  const zones = dedupe([
    ...automations.map(x => x.zone),
    ...(contents?.devices ? contents?.devices.map(x => x.zone) : []),
  ])

  return zones
}
