import { AutomationTrigger, Measurement, UserPreferences } from "types"
import { hourToSecond, tempId } from "../util"
import { defaultOutdoorInputsByMeasurement } from "../outdoor-condition"

const thresholds = {
    pmGoodToFair: 11.7,
    pmFairToGood: 10,
    pmFairToPoor: 33.2,
    pmPoorToFair: 30,
    vocGoodToFair: 212,
    vocFairToGood: 200,
    vocFairToPoor: 850,
    vocPoorToFair: 800,
}

const defaultSetPoints = (measurementType: Measurement, setPointUnit: 'fahrenheit' | 'celsius' = 'celsius') => {
    let start_set_point
    let stop_set_point

    if (measurementType === 'pm25') {
        start_set_point = thresholds.pmGoodToFair
        stop_set_point = thresholds.pmFairToGood
    }

    if (measurementType === 'voc') {
        start_set_point = thresholds.vocGoodToFair
        stop_set_point = thresholds.vocFairToGood
    }

    if (measurementType === 'humidity') {
        start_set_point = 50
        stop_set_point = 0
    }

    // TODO get correct values
    if (measurementType === 'radon') {
        start_set_point = 50
        stop_set_point = 0
    }

    if (measurementType === 'dew_point') {
        if (setPointUnit === 'fahrenheit') {
            start_set_point = 52
        } else {
            start_set_point = 11
        }
        stop_set_point = 0
    }

    return {
        start_set_point,
        stop_set_point,
    }
}

const defaultOutdoorSetPoints = (measurement, isFahrenheit = false) => {
    const results: { [key: string]: null | number } = {
        start_set_point: null,
        stop_set_point: null,
        low_end_start_set_point: null,
        high_end_start_set_point: null,
    }

    if (measurement === 'temperature') {
        if (isFahrenheit) {
            results.low_end_start_set_point = 50
            results.high_end_start_set_point = 78
        } else {
            results.low_end_start_set_point = 10
            results.high_end_start_set_point = 25
        }
    }

    if (measurement === 'epa_aqi') {
        results.start_set_point = 2
        results.stop_set_point = 2
    }

    if (measurement === 'humidity') {
        results.low_end_start_set_point = 30
        results.high_end_start_set_point = 60
    }

    if (measurement === 'dew_point') {
        if (isFahrenheit) {
            results.low_end_start_set_point = 40
            results.high_end_start_set_point = 60
        } else {
            results.low_end_start_set_point = 4
            results.high_end_start_set_point = 16
        }
    }

    return Object.fromEntries(Object.entries(results).filter(([x, y]) => y))
}

const defaultVocInput = (isMc) => ({
    id: tempId(),
    type: 'cam_threshold' as const,
    device_id: null,
    rising: true,
    currently_triggered: false,
    detection_duration: 3600,
    measurement: 'voc' as const,
    ...defaultSetPoints('voc'),
})

const defaultRadonInput = {
    id: tempId(),
    type: 'ecosense_threshold' as const,
    device_id: null,
    rising: true,
    currently_triggered: false,
    detection_duration: 3600,
    measurement: 'radon' as const,
    ...defaultSetPoints('radon'),
}

const defaultHumidityInput = {
    id: tempId(),
    type: 'cam_threshold',
    device_id: null,
    rising: true,
    currently_triggered: false,
    detection_duration: 3600,
    measurement: 'humidity',
    ...defaultSetPoints('humidity'),
}

const defaultDewPointInput = isFahrenheit => ({
    id: tempId(),
    type: 'cam_threshold',
    device_id: null,
    rising: true,
    currently_triggered: false,
    detection_duration: 3600,
    measurement: 'dew_point',
    set_point_unit: isFahrenheit ? 'fahrenheit' : 'celsius',
    ...defaultSetPoints('dew_point', isFahrenheit ? 'fahrenheit' : 'celsius'),
})

const defaultPmInput = isMc => ({
    id: tempId(),
    type: 'cam_threshold' as const,
    device_id: null,
    currently_triggered: false,
    detection_duration: 3600,
    measurement: 'pm25' as const,
    rising: true,
    ...defaultSetPoints('pm25')
})

const defaultOutdoorAirInput = (postal_code) => {
    return {
        id: tempId(),
        type: 'outdoor_weather_threshold',
        currently_triggered: false,
        detection_duration: 3600,
        measurement: 'epa_aqi' as const,
        postal_code,
        rising: true,
        ...defaultOutdoorSetPoints('epa_aqi'),
    }
}

const defaultOutdoorTemperatureRangeInput = (postal_code, isFahrenheit: boolean) => ({
    id: tempId(),
    type: 'outdoor_weather_range',
    currently_triggered: false,
    detection_duration: 3600,
    measurement: 'temperature' as const,
    set_point_unit: isFahrenheit ? 'fahrenheit' : 'celsius',
    postal_code,
    triggered_inside_range: true,
    ...defaultOutdoorSetPoints('temperature', isFahrenheit),
})

const defaultOutdoorDewPointRangeInput = (postal_code, isFahrenheit: boolean) => ({
    id: tempId(),
    type: 'outdoor_weather_range',
    currently_triggered: false,
    detection_duration: 3600,
    measurement: 'dew_point' as const,
    set_point_unit: isFahrenheit ? 'fahrenheit' : 'celsius',
    postal_code,
    triggered_inside_range: true,
    ...defaultOutdoorSetPoints('dew_point', isFahrenheit),
})

const defaultOutdoorHumidityRangeInput = (postal_code) => ({
    id: tempId(),
    type: 'outdoor_weather_range',
    currently_triggered: false,
    detection_duration: 3600,
    measurement: 'humidity' as const,
    postal_code,
    triggered_inside_range: true,
    ...defaultOutdoorSetPoints('humidity'),
})

const defaultFiltration = (output) => {
    const inputId = tempId()
    const ruleId = tempId()

    const inputs = [{
        id: inputId,
        type: 'cam_threshold' as const,
        device_id: null,
        currently_triggered: false,
        detection_duration: 3600,
        measurement: 'pm25' as const,
        rising: true,
        ...defaultSetPoints('pm25')
    }]

    const outputs = [output]

    const rules = [{
        id: ruleId,
        enabled: false,
        inputs: [inputId],
        outputs: [output.id],
        filtration: true,
        ventilation: false,
        humidity: false,
    }]

    return { inputs, rules, outputs }
}

const defaultAutomation = ({ dwelling_id, zone }) => ({
    id: tempId(),
    dwelling_id,
    status: '',
    active: true,
    template: 'none' as const,
    create_timestamp: '',
    zone,
    outputs: [],
    inputs: [],
    rules: [] as AutomationTrigger[],
    interlocks: [],
})

const defaultVentilation = (output) => {
    const inputId = tempId()
    const ruleId = tempId()

    // const outdoorAirInput = defaultOutdoorAirInput()

    const inputs = [
        // outdoorAirInput,
        {
            id: inputId,
            type: 'cam_threshold' as const,
            device_id: null,
            rising: true,
            currently_triggered: false,
            detection_duration: hourToSecond(4),
            measurement: 'voc' as const,
            ...defaultSetPoints('voc'),
        }]

    const outputs = [output]

    const rules = [{
        id: ruleId,
        enabled: false,
        inputs: [inputId],
        outputs: [output.id],
        filtration: false,
        ventilation: true,
        humidity: false,
    }]

    return { inputs, outputs, rules }
}

export type DefaultAutomation = ReturnType<typeof defaultVentilation>

const defaultHighHumidity = (output) => {
    const inputId = tempId()
    const ruleId = tempId()

    const inputs = [{
        id: inputId,
        type: 'cam_threshold',
        device_id: null,
        rising: true,
        currently_triggered: false,
        detection_duration: hourToSecond(4),
        measurement: 'dew_point',
        ...defaultSetPoints('dew_point'),
    }]

    const outputs = [{}]

    const rules: AutomationTrigger[] = [{
        id: ruleId,
        enabled: false,
        inputs: [inputId],
        outputs: [output.id],
        filtration: false,
        ventilation: false,
        humidity: true,
        override_on: false,
        override_timeout: '',
    }]

    return { inputs, outputs, rules }
}

const defaultLowHumidity = (output) => {
    const inputId = tempId()
    const ruleId = tempId()

    const inputs = [{
        id: inputId,
        type: 'cam_threshold' as const,
        device_id: null,
        rising: false,
        currently_triggered: false,
        detection_duration: hourToSecond(4),
        measurement: 'dew_point' as const,
        ...defaultSetPoints('dew_point'),
    }]

    const outputs = [{}]

    const rules: AutomationTrigger[] = [{
        id: ruleId,
        enabled: false,
        inputs: [inputId],
        outputs: [output.id],
        filtration: false,
        ventilation: false,
        humidity: true,
        override_on: false,
        override_timeout: '',
    }]

    return { inputs, outputs, rules }
}

const brands = [
    'Aldes',
    'Amana',
    'American Standard',
    'Aprilaire',
    'Bosch',
    'Broan-Nutone',
    'Bryant',
    'Carrier',
    'Coleman',
    'Daikin',
    'Fantech',
    'Fujitsu',
    'Goodman',
    'Greenheck',
    'Heil',
    'Hitachi',
    'Honeywell',
    'Keeprite',
    'Lennox',
    'LG',
    'Lifebreath',
    'Midea',
    'Mitsubishi Electric',
    'Napoleon',
    'Nortek (+ Frigidaire, Gibson)',
    'Panasonic',
    'Pioneer',
    'Renewaire',
    'Rheem',
    'Ruud',
    'Samsung',
    'Sanyo',
    'Santa Fe',
    'Sharp',
    'SystemAir',
    'S&P Advancing Ventilation',
    'Tappan',
    'Tempstar',
    'Toshiba',
    'Trane',
    'Ultra-Aire',
    'United Enertech',
    'Venmar',
    'Whirlpool',
    'York (Johnson Controls)',
    'Zehnder',
]

const defaultIndoorInputs = (preferences: UserPreferences) => ({
    'voc': defaultVocInput(preferences.voc_isMc),
    'radon': defaultRadonInput,
    'dew_point': defaultDewPointInput(preferences.temperature_isFahrenheit),
    'humidity': defaultHumidityInput,
    'pm25': defaultPmInput(preferences.pm_isMc),
})

const generateIndoorHumidityInput = preferences => measurement => device_id => {
    const input = measurement === 'dew_point' ? defaultDewPointInput(preferences.temperature_isFahrenheit) : defaultHumidityInput
    return { ...input, device_id }
}

const genreateOutdoorHumidityInput = preferences => measurement => post_code => defaultOutdoorInputsByMeasurement(preferences, post_code)[measurement]

export {
    generateIndoorHumidityInput,
    genreateOutdoorHumidityInput,
    defaultOutdoorTemperatureRangeInput,
    defaultOutdoorHumidityRangeInput,
    defaultOutdoorDewPointRangeInput,
    defaultOutdoorAirInput,
    defaultFiltration,
    defaultVentilation,
    defaultHighHumidity,
    defaultLowHumidity,
    defaultAutomation,
    defaultIndoorInputs,
    brands,
}
