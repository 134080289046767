import React, { Fragment } from 'react'
import { Close } from '@material-ui/icons'
import { useEffect } from 'react'
import Stack from 'ui/stack'
import {
  Box,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  styled,
} from '@material-ui/core'
import Muted from './muted'
import { parseEquipmentType } from 'utils/string-utils'
import AddNew from './install/add-new'
import {
  selectTempRule,
  setTemporaryRule,
  useRulesStore,
} from './store'
import { dedupe } from './useGetData'
import link from '../icons/link.svg'

const Pill = styled('span')({
  background: '#9bb3bf',
  color: 'white',
  padding: '2px 8px',
  borderRadius: '99px',
  fontSize: '10px',
  letterSpacing: '0.333px',
  display: 'inline-flex',
  alignItems: 'center',
  gridGap: '6px',
})

/**
 * works by rendering a select input for
 * each output object in an automation
 * rule. this component handles setting
 * the equipment outputs on the automation
 * state via the temp rule. a parent component
 * should maintain a list of avaialble outputs
 * so that globally we can see what's in play
 * in different rules
 * */
const SelectableEquipmentList = ({
  allOutputs,
  availableOutputs,
  removeSelected,
  setSelectedOutputs,
  ruleId,
}) => {
  const tempRule = useRulesStore(selectTempRule(ruleId))
  const selectedOutputs = tempRule.outputs.filter(x => x.equipment)

  useEffect(() => {
    const selectedOutputIds = [
      ...tempRule.outputs.map(x => x.id),
      ...tempRule.outputs.flatMap(x => x.interlocked_blowers),
      ...tempRule.outputs.flatMap(x => x.interlocked_non_blowers),
    ]

    const outputsToRemove = selectedOutputs.map(x => x.id).filter(x => selectedOutputIds.includes(x))
    if (outputsToRemove.length) removeSelected(outputsToRemove)

    setSelectedOutputs(selectedOutputIds)
  }, [JSON.stringify(selectedOutputs)])

  const handleChangeEquipment = index => event => {
    const { value } = event.target
    const outputId = parseInt(value)
    const selection = allOutputs.find(x => x.id === outputId)

    const newOutputs = tempRule.outputs.map((x, i) => i === index ? selection : x)
    const oldOutput = tempRule.outputs[index]

    const update = {
      outputs: newOutputs,
      trigger: {
        ...tempRule.trigger,
        outputs: newOutputs.map(x => x.id),
      },
    }

    removeSelected([oldOutput.id])
    setTemporaryRule(ruleId, update)
  }

  const onClickMoreEquipment = () => {
    if (!availableOutputs.length) return
    const nextInLine = availableOutputs[0]
    let outputs = [...tempRule.outputs, nextInLine]

    if (nextInLine.interlocked_blowers?.length) {
      outputs = outputs.filter(x => {
        return !nextInLine.interlocked_blowers?.includes(x.id)
      })
    }

    const update = {
      outputs,
      trigger: {
        ...tempRule.trigger,
        outputs: outputs.map(x => x.id)
      }
    }

    setTemporaryRule(ruleId, update)
  }

  const onClickRemoveEquipment = (outputId) => () => {
    const newOutputs = tempRule.outputs.filter(x => x.id !== outputId)
    const newOutputIds = newOutputs.map(x => x.id)

    const outputsToRemove = [
      outputId,
      ...(allOutputs.find(x => x.id === outputId)?.interlocked_blowers || []),
    ]

    const update = {
      outputs: newOutputs,
      trigger: {
        ...tempRule.trigger,
        outputs: newOutputIds,
      },
    }

    setTemporaryRule(ruleId, update)
    removeSelected(outputsToRemove)
  }

  const menuItemIsDisabled = (parentOutput, output) => {
    if (parentOutput.interlocked_blowers.includes(output.id)) return false
    if (parentOutput.interlocked_non_blowers.includes(output.id)) return false
    return !availableOutputs.some(x => x.id === output.id)
  }

  const interlockedBlowers = output => dedupe(output.interlocked_blowers)

  return (
    <Stack spacing={4}>
      <Box
        display='flex'
        flexDirection='column'
      >
        {selectedOutputs.map((output, index) => (
          <Fragment key={output.id}>
            <Box
              mt={index > 0 ? 2 : 0}
              display='flex'
              alignItems='center'
            >
              <FormControl
                id={`equipment_select_${output.id}`}
                size='small'
                variant='outlined'
                style={{
                  width: '100%',
                }}
              >
                <InputLabel
                  id={`equipment_select_${output.id}`}>Equipment</InputLabel>
                <Select
                  id={`equipment_select_${output.id}`}
                  name={`equipment_select`}
                  labelId={`equipment_select`}
                  label='Equipment'
                  value={output.id || ''}
                  onChange={handleChangeEquipment(index)}
                >
                  <MenuItem
                    disabled
                    value={``}
                  >
                    Select...
                  </MenuItem>
                  {allOutputs.filter(x => x.equipment).map(x => (
                    <MenuItem
                      key={x.id}
                      disabled={menuItemIsDisabled(output, x)}
                      value={`${x.id}`}
                    >
                      <Box display='flex' flexDirection='column'>
                        {parseEquipmentType(x.equipment.type)}
                        <Muted style={{ fontSize: '14px' }}>{x.equipment.brand}</Muted>
                      </Box>
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              {tempRule.outputs.length > 1 && (
                <Box ml={1}>
                  <IconButton
                    onClick={onClickRemoveEquipment(output.id)}
                    disabled={selectedOutputs.length < 1}
                    size='small'
                  >
                    <Close color='error' />
                  </IconButton>
                </Box>
              )}
            </Box>
            {
              (interlockedBlowers(output) || [])?.length ?
                (interlockedBlowers(output) || []).filter(x => x).map(id => (
                  <Box
                    key={id}
                    ml={2}
                    mt='10px'
                  >
                    <Pill >
                      <img style={{ width: '16px', height: '16px' }} src={link} />
                      interlocked with
                    </Pill>
                    <Box
                      mt='6px'
                      display='flex'
                      flexDirection='column'
                    >
                      <span>
                        {parseEquipmentType(allOutputs.find(x => x.id === id)?.equipment?.type) || ''}
                      </span>
                      <Muted style={{ fontSize: '14px' }}>{allOutputs.find(x => x.id === id)?.equipment?.brand}</Muted>
                    </Box>
                  </Box>
                )) :
                null}
          </Fragment>
        ))}
        {!availableOutputs.length ? (
          null
        ) : (
          <AddNew
            mt={selectedOutputs.length > 0 ? 2 : 0}
            onClick={onClickMoreEquipment}
          >
            Add equipment
          </AddNew>
        )}
      </Box>
    </Stack>
  )
}

export default SelectableEquipmentList
