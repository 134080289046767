import * as React from 'react'
import {
    Card,
} from './new-install'
import {
    Box,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
} from '@material-ui/core'
import { parseEquipmentType } from 'utils/string-utils'
import Muted from '../muted'
import EntityHeader from '../entity-header'
import RuleTitle from '../rule-title'

function SingleInterlock({
    blowers,
    nonBlowers,
    blowerId,
    nonBlowerId,
    setBlowerId,
    setNonBlowerId,
    handleDelete,
    loading = false,
    ...rest
}) {
    return (
        <Card p={2} pt={1}>
            <EntityHeader
                loading={loading}
                handleDelete={handleDelete}
            >
                Interlock
            </EntityHeader>
            <Box mt={3}>
                <FormControl
                    size='small'
                    variant='outlined'
                    fullWidth
                >
                    <InputLabel
                        htmlFor='interlock1'
                    >Equipment</InputLabel>
                    <Select
                        id='interlock1'
                        name='interlock1'
                        label='Equipment'
                        defaultValue='Select...'
                        value={nonBlowerId}
                        error={!Boolean(nonBlowerId)}
                        onChange={e => setNonBlowerId(e.target.value)}
                    >
                        <MenuItem disabled value='Select...'>
                            Select...
                        </MenuItem>
                        {nonBlowers.filter(x => x.equipment).map(x => (
                            <MenuItem
                                key={x.id}
                                value={x.id}>
                                <Box
                                    display='flex'
                                    flexDirection='column'
                                >
                                    {parseEquipmentType(x.equipment?.type)}
                                    {x.equipment?.brand ? (
                                        <Muted style={{ fontSize: '14px' }}> {x.equipment?.brand}</Muted>
                                    ) : null}
                                </Box>
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </Box>
            <Box mt={2}>
                <RuleTitle>
                    with
                </RuleTitle>
            </Box>
            <Box mt={2}>
                <FormControl
                    size='small'
                    variant='outlined'
                    fullWidth
                >
                    <InputLabel
                        htmlFor='blower'
                    >Blower</InputLabel>
                    <Select
                        id='blower'
                        name='blower'
                        label='Blower'
                        defaultValue='Select...'
                        error={!Boolean(blowerId)}
                        value={blowerId}
                        onChange={e => setBlowerId(e.target.value)}
                    >
                        <MenuItem disabled value='Select...'>
                            Select...
                        </MenuItem>
                        {blowers.filter(x => x.equipment).map(x => (
                            <MenuItem
                                key={x.id}
                                value={x.id}
                            >
                                <Box
                                    display='flex'
                                    flexDirection='column'
                                >
                                    {parseEquipmentType(x.equipment?.type)}
                                    {x.equipment?.brand ? (
                                        <Muted style={{ fontSize: '14px' }}> {x.equipment?.brand}</Muted>
                                    ) : null}
                                </Box>
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </Box>
            {rest.renderFooter ? rest.renderFooter() : null}
        </Card>
    )
}

export default SingleInterlock
