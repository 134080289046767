import * as React from 'react'
import Stack from "ui/stack";
import RuleTitle from "./rule-title";
import { selectRule, useRulesStore } from './store';
import { Box, Button } from '@material-ui/core';
import StaticInput from './static-input';
import EquipmentSection from './equipment-section';
import { RuleHeader } from './rule';
import OverrideSwitch from './override-switch';

function StaticRule({
    ruleId,
    setHeight,
    handleClickEdit,
}) {
    const rule = useRulesStore(selectRule(ruleId))
    const outputs = rule.outputs?.filter(x => x.equipment)
    const ref = React.useRef<HTMLDivElement>(null)

    React.useEffect(() => {
        let resizeObserver: ResizeObserver

        if (ruleId && ref.current) {
            const handleResize = () => {
                if (!ref.current) return
                const { height } = ref.current.getBoundingClientRect()
                setHeight(height + 24)
            }

            resizeObserver = new ResizeObserver(handleResize)
            resizeObserver.observe(ref.current)
        }

        return () => {
            if (resizeObserver && ref.current) return resizeObserver.unobserve(ref.current)
        }
    }, [ruleId, ref.current])

    return (
        <div ref={ref}>
            <RuleHeader
                override_on={rule.trigger.override_on}
                override_timeout={rule.trigger.override_timeout}
                currently_triggered={rule.trigger.currently_triggered}
                ruleId={rule.id}
                automationId={rule.automationId}
            />
            <Box mt={2} mb={1} display='flex' justifyContent='space-between'>
                <RuleTitle>
                    Equipment
                </RuleTitle>
                <OverrideSwitch ruleId={ruleId} />
            </Box>
            <EquipmentSection outputs={outputs} />
            {rule.inputs.map((input, index) => (
                <Stack
                    style={{ marginTop: '16px' }}
                    key={input.id}
                    spacing={1}
                >
                    <RuleTitle>
                        {(index > 0) ? 'And' : 'When'}
                    </RuleTitle>
                    <StaticInput ruleId={rule.id} inputId={input.id} />
                </Stack>
            ))}
            <Box
                width='100%'
                display='flex'
                mt={2}
                justifyContent='flex-end'
                gridGap='16px'
            >
                <Button
                    color='primary'
                    size='small'
                    onClick={handleClickEdit}
                    variant='outlined'>
                    Edit
                </Button>
            </Box>
        </div>
    )
}

export default StaticRule
