import * as React from 'react'
import { Box } from "@material-ui/core";
import ButtonSpinner from "./button-spinner";

function Loading() {
    return <Box
        width='100%'
        height='100%'
        display='flex'
        alignItems='center'
        justifyContent='center'
        mt={4}
    ><ButtonSpinner /></Box>
}

export default Loading
