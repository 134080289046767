import { AnyAction, Action } from 'redux'
import { ThunkAction } from 'redux-thunk'
import { UserPreferencesEnum } from 'utils/constants/preference-enums'
import { Dwelling } from 'state-mngt/models/dwelling'
import { ServiceCompanyRole } from 'state-mngt/models/serviceCompany'
import {
  User, UserDemoName, DemoHintElements, DemoHintElementName,
} from 'state-mngt/models/user'
import demoService from 'state-mngt/services/data/demo-service'
import dwellingService from 'state-mngt/services/dwelling-service'
import userService from 'state-mngt/services/user-service'
import { RootState } from 'state-mngt/store'
import { RequestsAdapter } from 'state-mngt/models/http'
import { ActionPayload } from 'state-mngt/models/redux'
import { DEMO_STEPS } from 'features/demo-data/demo-data'
import {
  getUserDemoActiveDemo,
  getUserDemoActiveStep,
  getUserCompletedDemos,
  getUserId,
} from 'state-mngt/selectors/user-selectors'
import { editCompanyMemberDetails } from 'state-mngt/actions/company-actions'

export enum ActionType {
  SetUserDwellings = 'user/SET_USER_DWELLINGS',
  SetCompanyRole = 'user/SET_COMPANY_ROLE',
  SetUser = 'user/SET_USER',
  EnableDemoMode = 'user/ENABLE_DEMO_MODE',
  SetCompletedDemos = 'user/SET_COMPLETED_DEMOS',
  StartDemo = 'user/START_DEMO',
  SetDemoStep = 'user/SET_DEMO_STEP',
  CancelDemo = 'user/CANCEL_DEMO',
  FinishDemo = 'user/FINISH_DEMO',
  DisableDemoMode = 'user/DISABLE_DEMO_MODE',
  HideHintElement = 'user/HIDE_HINT_ELEMENT',
  CompleteDemoIntro = 'user/COMPLETE_DEMO_INTRO',
}

export type SetDwellingsAction = ActionPayload<ActionType.SetUserDwellings, Dwelling[]>;
export type SetCompanyRoleAction = ActionPayload<ActionType.SetCompanyRole, ServiceCompanyRole | undefined>;
export type SetUserAction = ActionPayload<
  ActionType.SetUser,
  {
    user: User | null;
    loadingUserError: boolean;
    isLoadingUser: boolean;
  }
>;
export type SetCompletedDemosAction = ActionPayload<ActionType.SetCompletedDemos, UserDemoName[]>;
export type EnableDemoModeAction = ActionPayload<ActionType.EnableDemoMode, UserDemoName | null>;
export type DisableDemoModeAction = Action<ActionType.DisableDemoMode>;
export type StartDemoAction = ActionPayload<
  ActionType.StartDemo,
  {
    name: UserDemoName;
    step: number;
    hintElements: DemoHintElements;
  }
>;
export type SetDemoStepAction = ActionPayload<ActionType.SetDemoStep, { step: number; hintElements: DemoHintElements }>;
export type FinishDemoAction = Action<ActionType.FinishDemo>;
export type CancelDemoAction = Action<ActionType.CancelDemo>;
export type CompleteDemoIntroAction = Action<ActionType.CompleteDemoIntro>;
export type HideHintElementAction = ActionPayload<ActionType.HideHintElement, DemoHintElementName>;

export const setUserDwellings = (dwellings: Dwelling[]): SetDwellingsAction => ({
  type: ActionType.SetUserDwellings,
  payload: dwellings,
})

export const setCompanyRole = (role?: ServiceCompanyRole): SetCompanyRoleAction => ({
  type: ActionType.SetCompanyRole,
  payload: role,
})

export const setUser = (user: User | null, isLoadingUser: boolean, loadingUserError: boolean): SetUserAction => ({
  type: ActionType.SetUser,
  payload: {
    user,
    isLoadingUser,
    loadingUserError,
  },
})

export const setCompletedDemos = (demos: UserDemoName[]): SetCompletedDemosAction => ({
  type: ActionType.SetCompletedDemos,
  payload: demos,
})

export const getUserDetails = async (userId: number): Promise<User> => {
  const dbUser = await userService.getUserDetails(userId).catch(() => null)

  if (dbUser) {
    return dbUser
  } else {
    return {
      id: 1,
      email: '',
      beta: false,
      pm_unit: UserPreferencesEnum.PM_MC,
      voc_unit: UserPreferencesEnum.VOC_MC,
      temperature_unit: UserPreferencesEnum.FAHRENHEIT,
      radon_unit: UserPreferencesEnum.PIC,
      pro_portal_demo_enabled: true,
      pro_portal_tour_enabled: true,
      pro_portal_tour_notification_shown: false,
    }
  }
}

type ThunkResult<R = void> = ThunkAction<R, RootState, undefined, AnyAction>;

/**
 * call api to get info on current user
 * @returns - void
 */
export const getUser = (): ThunkResult => async (dispatch) => {
  const dbUser = await userService.getUser().catch(() => null)

  if (dbUser) {
    dispatch(setUser(dbUser, false, false))
  } else {
    dispatch(
      setUser(
        null,
        false,
        true,
      ),
    )
  }
}

/**
 * call api to get user role
 * @returns - void
 */
export const getCompanyRole = (): ThunkResult => async (dispatch) => {
  const companyRole = await userService.getServiceCompanyRole().catch(() => null)

  if (companyRole) {
    dispatch(setCompanyRole(companyRole))
  } else {
    dispatch(setCompanyRole(undefined))
  }
}

/**
 * call api to get dwellings user have access to
 * @param companyId - number
 * @returns - Dwelling[] | undefined
 */
export const getUserDwellings =
  (companyId: number, requestsAdapter: RequestsAdapter): ThunkResult =>
    async (dispatch, getState) => {
      try {
        const dwellings = await requestsAdapter
          .make(dwellingService.getDwellings(companyId))
          .catch(requestsAdapter.throwOnAbort)

        if (dwellings) {
          dispatch(setUserDwellings(dwellings))
        } else {
          dispatch(setUserDwellings([]))
        }
      } catch {
        // aborted
      }
    }

/**
 * call api to update a user
 * @param userId - number
 * @param user - User
 * @param isCurrentUser - boolean
 * @returns - void
 */
export const updateUser = (userId: number, user: User, isCurrentUser: boolean): ThunkResult<Promise<boolean>> =>
  async (dispatch) => {

    try {
      // phone cannot be '' when passed to api
      await userService.updateUserDetails(userId, {
        ...user,
        phone: user.phone === '' ? undefined : user.phone,
      })

      if (isCurrentUser) {
        dispatch(
          setUser(
            {
              ...user,
            },
            false,
            false,
          ),
        ) // update current user in store
      } else {
        dispatch(editCompanyMemberDetails(user)) // update company member info in store
      }

      return true
    } catch {
      return false
    }
  }

export const enableDemoMode = (preferableDwellingId?: number): EnableDemoModeAction => ({
  type: ActionType.EnableDemoMode,
  payload: demoService.getDemoNameByDwellingId(preferableDwellingId || 0),
})

export const disableDemoMode = (): DisableDemoModeAction => ({
  type: ActionType.DisableDemoMode,
})

export const startDemo = (name: UserDemoName): StartDemoAction => ({
  type: ActionType.StartDemo,
  payload: {
    name,
    step: 0,
    hintElements: getDemoHintElements(name, 1),
  },
})

export const finishDemo = (): FinishDemoAction => ({
  type: ActionType.FinishDemo,
})

export const cancelDemo = (): CancelDemoAction => ({
  type: ActionType.CancelDemo,
})

export const hideHintElement = (name: DemoHintElementName): HideHintElementAction => ({
  type: ActionType.HideHintElement,
  payload: name,
})

export const setDemoStep = (step: number, hintElements: DemoHintElements = {
}): SetDemoStepAction => ({
  type: ActionType.SetDemoStep,
  payload: {
    step,
    hintElements,
  },
})

export const completeDemoIntroModal = (): CompleteDemoIntroAction => ({
  type: ActionType.CompleteDemoIntro,
})

const getDemoHintElements = (demoName: UserDemoName | null, demoStep: number): DemoHintElements => {
  switch (demoName) {
    case UserDemoName.Demo1: {
      if (demoStep === 1) {
        return {
          [DemoHintElementName.Timeframe]: {
            name: DemoHintElementName.Timeframe,
            text: 'Default view',
          },
        }
      }
      if (demoStep === 2) {
        return {
          [DemoHintElementName.Timeframe]: {
            name: DemoHintElementName.Timeframe,
            text: 'Last 3 months view',
          },
        }
      }
      if (demoStep === 5) {
        return {
          [DemoHintElementName.Timeframe]: {
            name: DemoHintElementName.Timeframe,
            text: 'Last 2 weeks view',
          },
        }
      }
      return {
      }
    }
    case UserDemoName.Demo2: {
      if (demoStep === 1) {
        return {
          [DemoHintElementName.Timeframe]: {
            name: DemoHintElementName.Timeframe,
            text: 'Default view',
          },
        }
      }
      if (demoStep === 2) {
        return {
          [DemoHintElementName.DataType]: {
            name: DemoHintElementName.DataType,
            text: 'RH (Relative Humidity)',
          },
        }
      }
      if (demoStep === 3) {
        return {
          [DemoHintElementName.SecondDataType]: {
            name: DemoHintElementName.SecondDataType,
            text: 'Select temp on the right axis',
          },
          [DemoHintElementName.Timeframe]: {
            name: DemoHintElementName.Timeframe,
            text: 'Last 3 months view',
          },
        }
      }
      if (demoStep === 4) {
        return {
          [DemoHintElementName.SecondDataType]: {
            name: DemoHintElementName.SecondDataType,
            text: 'View relative to temperature',
          },
        }
      }
      if (demoStep === 5) {
        return {
          [DemoHintElementName.SecondDataType]: {
            name: DemoHintElementName.SecondDataType,
            text: 'View relative to temperature',
          },
        }
      }
      return {
      }
    }
    case UserDemoName.Demo3: {
      if (demoStep === 1) {
        return {
          [DemoHintElementName.Timeframe]: {
            name: DemoHintElementName.Timeframe,
            text: 'Default view',
          },
        }
      }
      if (demoStep === 2) {
        return {
          [DemoHintElementName.Timeframe]: {
            name: DemoHintElementName.Timeframe,
            text: 'Last 3 months view',
          },
          [DemoHintElementName.DataType]: {
            name: DemoHintElementName.DataType,
            text: 'tVOC',
          },
        }
      }
      if (demoStep === 4) {
        return {
          [DemoHintElementName.Timeframe]: {
            name: DemoHintElementName.Timeframe,
            text: 'Last 2 weeks view',
          },
        }
      }
      return {
      }
    }
  }
  return {
  }
}

export const goToPreviousDemoStep = (): ThunkResult => (dispatch, getState) => {
  const state = getState()
  const activeStep = getUserDemoActiveStep(state)

  if (activeStep !== null) {
    const nextStep = Math.max(activeStep - 1, 1)
    dispatch(setDemoStep(nextStep, getDemoHintElements(getUserDemoActiveDemo(state), nextStep)))
  }
}

export const goToNextDemoStep = (): ThunkResult => (dispatch, getState) => {
  const state = getState()
  const activeDemo = getUserDemoActiveDemo(state)
  const activeStep = getUserDemoActiveStep(state)

  if (activeDemo && activeStep !== null) {
    if (activeStep === DEMO_STEPS[activeDemo]) {
      dispatch(finishDemo())
      const userId = getUserId(state)
      if (userId !== undefined) {
        userService.saveUserCompletedDemos(userId, getUserCompletedDemos(getState())).catch(() => { })
      }
    } else {
      const nextStep = Math.min(activeStep + 1, DEMO_STEPS[activeDemo])
      dispatch(setDemoStep(nextStep, getDemoHintElements(activeDemo, nextStep)))
    }
  }
}
