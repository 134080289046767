import {
  Box,
  FormControlLabel,
  Radio,
  RadioGroup,
  TextField,
  Typography,
  makeStyles,
} from '@material-ui/core'
import React, { memo, useEffect, useState } from 'react'
import { InputControlProps } from './input'
import Emphasis from './emphasis'
import {
  selectTempInput,
  setTemporaryRuleInput,
  useRulesStore,
} from './store'
import {
  displayRange,
  humanizeMeasurement,
  start_set_point,
  stop_set_point,
  useSkipFirstRender,
  findUnit,
  parseUnit,
  airflowPhrasingFromFactor,
  isWithinFive,
  isWithin,
} from './util'
import Stack from 'ui/stack'
import Muted from './muted'
import useConvertParticulateUnit from 'utils/hooks/useConvertParticulateUnit'
import GraphicVentilationGood from './ventilation-diagram-good.png'
import GraphicVentilationFair from './ventilation-diagram-fair.png'
import GraphicVentilationCustom from './ventilation-diagram-custom.png'
import GraphicFiltrationGood from './filtration-diagram-good.png'
import GraphicFiltrationFair from './filtration-diagram-fair.png'
import GraphicFiltrationCustom from './filtration-diagram-custom.png'
import RuleTitle from './rule-title'

const useClasses = makeStyles(theme => ({
  textField: {
    marginLeft: '32px',
    maxWidth: '164px',
  },
}))

const startPointToFactor = measurement => unit => (startPoint: number) => {
  if (!measurement) return ''
  if (!unit) return ''
  const startPoints = start_set_point[measurement][unit]

  /**
   * this stuff is because we have deviation caused
   * by rounding values. this is meant to nudge values into
   * a category if they're within a deviation
   */
  let within = isWithinFive
  if (measurement === 'radon' && unit === 'picocuries') within = isWithin(0.2)
  return Object.keys(startPoints).find(factor => within(startPoints[factor], startPoint)) || 'custom'
}

const stopPoint = (startPoint: number) => startPoint * 0.9
const roundToHundredth = (x: number) => Math.round(x * 100) / 100

function AirFlow({
  ruleId,
  inputId,
}: InputControlProps) {
  const [customSetPoint, setCustomSetPoint] = useState('') // store it locally just so it updates visually on change without rendering the whole tree
  const [selectedFactor, setSelectedFactor] = useState('fair')
  const [initialSetComplete, setInitialSetComplete] = useState(false)

  const input = useRulesStore(selectTempInput(ruleId, inputId))
  const classes = useClasses()
  const unit = input.set_point_unit || findUnit(input?.measurement)
  const { convertedUnit, display, format } = useConvertParticulateUnit(input?.measurement, unit)

  useSkipFirstRender(() => {
    if (!initialSetComplete) return
    if (selectedFactor !== 'custom') return

    let _customSetPoint = parseFloat(customSetPoint)

    if (input?.start_set_point === _customSetPoint) return
    if (unit !== convertedUnit) {
      if (input?.start_set_point === format(_customSetPoint)) return
    }

    setTemporaryRuleInput(ruleId, input.id, {
      start_set_point: roundToHundredth(format(_customSetPoint)),
      stop_set_point: roundToHundredth(stopPoint(format(_customSetPoint))),
    })
  }, [
    unit,
    format,
    convertedUnit,
    customSetPoint,
  ])

  useEffect(() => {
    if (!input) return
    if (!convertedUnit) return
    if (initialSetComplete) return

    const good = start_set_point[input.measurement][unit]['good']
    const fair = start_set_point[input.measurement][unit]['fair']

    const isGood = input?.start_set_point === good
    const isFair = input?.start_set_point === fair
    const isCustom = !isGood && !isFair

    if (isGood) setSelectedFactor('good')
    if (isCustom) setSelectedFactor('custom')

    const setPoint = display(input.start_set_point) || 0
    setCustomSetPoint(`${Math.round(setPoint * 100) / 100}`)
    setInitialSetComplete(true)
  }, [
    input?.start_set_point,
    display,
    convertedUnit,
    initialSetComplete,
  ])

  useEffect(() => {
    /**
     * a bit hacky but a converted unit can change
     * at any time so it must be stateful. when it
     * updates we need to reevaluate the initial
     * custom set point
     */
    if (unit !== convertedUnit) setInitialSetComplete(false)
  }, [unit, convertedUnit])

  const handleChange = (event, value) => {
    setSelectedFactor(value)

    if (value === 'custom') return
    if (!input) return

    const start = start_set_point[input.measurement]?.[unit]?.[value]
    const stop = stop_set_point[input.measurement]?.[unit]?.[value]

    return setTemporaryRuleInput(ruleId, input?.id, {
      start_set_point: start,
      stop_set_point: stop,
    })
  }

  const onChangeCustomSetPoint = e => {
    setCustomSetPoint(e.target.value)
  }

  if (!convertedUnit) return null
  if (!input) return null

  const triggerAction = () => {
    const parsedUnit = parseUnit(convertedUnit)

    if (selectedFactor === 'custom') {
      return `worse than ${customSetPoint} ${parsedUnit}`
    }

    return `${airflowPhrasingFromFactor[input.measurement][convertedUnit][selectedFactor]}`
  }

  const humanizedMeasurement = humanizeMeasurement(input.type, input.measurement)

  const renderVisualization = () => {
    let src

    if (input.measurement === 'voc') {
      if (selectedFactor === 'good') src = GraphicVentilationGood
      if (selectedFactor === 'fair') src = GraphicVentilationFair
      if (selectedFactor === 'custom') src = GraphicVentilationCustom
    }

    if (input.measurement === 'pm25') {
      if (selectedFactor === 'good') src = GraphicFiltrationGood
      if (selectedFactor === 'fair') src = GraphicFiltrationFair
      if (selectedFactor === 'custom') src = GraphicFiltrationCustom
    }

    if (!src) return null

    return (
      <Box maxWidth='400px'>
        <img style={{ maxWidth: '100%' }} src={src} />
        <Box
          display='flex'
          justifyContent='space-around'
        >
          <RuleTitle>Equipment on</RuleTitle>
          <RuleTitle>Equipment off</RuleTitle>
        </Box>
      </Box>
    )
  }

  return (
    <Stack>
      <Typography
        variant='body1'
      >
        <Emphasis>
          {humanizedMeasurement} <Muted>levels are</Muted>
          {` `}{triggerAction()}{` `}
        </Emphasis>
      </Typography>
      <RadioGroup
        value={selectedFactor}
        aria-label={input.measurement}
        name={input.measurement}
        onChange={handleChange}>
        <FormControlLabel value='good' control={<Radio />} label={
          <Typography>
            Good
            <Muted>{` `}{displayRange[input.measurement][convertedUnit]['good']}</Muted>
          </Typography>
        } />
        <FormControlLabel value='fair' control={<Radio />} label={
          <Typography>
            Fair
            <Muted>{` `}{displayRange[input.measurement][convertedUnit]['fair']}</Muted>
          </Typography>
        } />
        <FormControlLabel value='custom' control={<Radio />} label={
          <Typography>
            Custom
          </Typography>
        } />
        {(selectedFactor === 'custom') ? (
          <TextField
            autoFocus
            className={classes.textField}
            value={customSetPoint}
            onChange={onChangeCustomSetPoint}
          />
        ) : null}
      </RadioGroup>
      {renderVisualization()}
    </Stack>
  )
}

export {
  startPointToFactor,
}

export default memo(AirFlow)
