import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  makeStyles,
  Typography,
} from '@material-ui/core'
import React from 'react'
import { theme } from 'theme'
import ButtonSpinner from '../button-spinner'

const useStyles = makeStyles({
  dialogContent: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  dialogActions: {
    margin: '5px',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    alignContent: 'stretch',
  },
})

const ConfirmHumidityDialog = ({ onClickYes, onClickCancel, loading, isOpen, measurement }) => {
  const classes = useStyles()

  const _onClickYes = () => {
    onClickYes()
  }

  const _onClickCancel = () => {
    onClickCancel()
  }

  return (
    <Dialog
      aria-labelledby="delete-dialog-title"
      maxWidth='sm'
      open={isOpen}>
      <DialogTitle id="delete-dialog-title" style={{
        paddingBottom: '0',
      }} disableTypography>
        <Typography variant="h6" style={{
          color: theme.palette.grey[500],
        }}>Change all humidity conditions to {measurement === 'humidity' ? '%' : 'degrees'}?</Typography>
      </DialogTitle>
      <DialogContent>
        <Typography
          variant="body1"
          color="textPrimary">
          Humidity condition values will be set back to defaults. This change will effect all existing automations in this zone.
        </Typography>
      </DialogContent>
      <DialogActions className={classes.dialogActions}>
        <Button
          variant="contained"
          color="secondary"
          disabled={loading}
          size='small'
          onClick={_onClickCancel}>
          Cancel
        </Button>
        <Button
          variant="contained"
          disabled={loading}
          color="primary"
          size='small'
          onClick={_onClickYes}>
          {loading ? (
            <ButtonSpinner />
          ) : (
            <>
              Yes, I'm sure
            </>
          )}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default ConfirmHumidityDialog
