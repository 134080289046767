import React from 'react'
import {
  createTempAndCanonRule,
  selectEquipmentRulesByPillar,
  selectFirstEquipmentAutomation,
  useAutomationsStore,
  useRulesStore,
} from './store'
import {
  AutomationPillar,
  AutomationTrigger,
  EquipmentTypes,
} from 'types'
import Stack from 'ui/stack'
import useClasses from './useClasses'
import Muted from './muted'
import useCurrentZone from 'utils/hooks/useCurrentZone'
import ColumnTitle from './column-title'
import { useLoadingStore } from './useGetData'
import Loading from './loading'
import { Box, Typography } from '@material-ui/core'
import AddNew from './install/add-new'
import { defaultIndoorInputs, defaultOutdoorAirInput } from './install/data'
import { tempId } from './util'
import { getDefaultMeasurementByPillar } from './indoor-condition'
import { useAppSelector } from 'utils/hooks/reduxTypes'
import { getUserPreferences } from 'state-mngt/selectors/user-selectors'
import HumidityUnitSelector from './humidity-unit-selector'
import useCurrentDwellingPostalCode from 'utils/hooks/useCurrentDwellingPostalCode'
import RuleWrapper from './rule-wrapper'
import useMonitorInCurrentZone from '../charts/device-chart/useMonitorInCurrentZone'

const Pillar = ({ pillar, dwellingId, ...rest }: { pillar: AutomationPillar, dwellingId: number }) => {
  const zone = useCurrentZone()
  const rules = useRulesStore(selectEquipmentRulesByPillar(dwellingId)(pillar)(zone))

  if (!rules?.length) return (
    <Muted>
      No {pillar} automation rules
    </Muted>
  )

  return (
    <Stack
      {...rest}
    >
      {
        rules.map(rule => (
          <RuleWrapper
            key={rule.id}
            rule={rule}
          />
        ))
      }
    </Stack>
  )
}

function NewAutomation({ pillar, dwellingId }) {
  const preferences = useAppSelector(getUserPreferences)
  const zone = useCurrentZone()
  const automation = useAutomationsStore(selectFirstEquipmentAutomation(dwellingId, zone))

  const monitor = useMonitorInCurrentZone()

  const ventDehuFan = automation?.outputs.find(x =>
    x.equipment?.type === EquipmentTypes.VENTILATING_DEHUMIDIFIER &&
    x.equipment_component === 1
  )
  const ventDehuCompressor = automation?.outputs.find(x =>
    x.equipment?.type === EquipmentTypes.VENTILATING_DEHUMIDIFIER &&
    x.equipment_component === 0
  )
  const dehu = automation?.outputs.find(x => x.equipment?.type === EquipmentTypes.DEHUMIDIFIER)
  const hu = automation?.outputs.find(x => x.equipment?.type === EquipmentTypes.HUMIDIFIER)
  const airHandler = automation?.outputs.find(x => x.equipment?.type === EquipmentTypes.CENTRAL_AIR_HANDLER)
  const mechVentilation = automation?.outputs.find(x => x.equipment?.type === EquipmentTypes.MECHANICAL_VENTILATION)
  const damper = automation?.outputs.find(x => x.equipment?.type === EquipmentTypes.OUTDOOR_AIR_DAMPER)

  const hasDehumidifier = Boolean(dehu || ventDehuCompressor)

  const postal_code = useCurrentDwellingPostalCode()

  const onClickNewAutomation = () => {
    if (!automation) return
    if (!monitor) return console.error('[onClickNewAutomation] No monitor found in zone')

    const triggerId = tempId()
    const measurement = getDefaultMeasurementByPillar(pillar)
    const defaultIndoorInput = defaultIndoorInputs(preferences)[measurement]
    defaultIndoorInput.device_id = monitor?.id

    const additionalInputs: any[] = []

    const newTrigger: AutomationTrigger = {
      filtration: pillar === 'filtration',
      ventilation: pillar === 'ventilation',
      humidity: pillar === 'humidity',
      id: triggerId,
      enabled: true,
      override_on: true,
      override_timeout: null,
      inputs: [defaultIndoorInput.id],
      outputs: [],
    }

    if (pillar === 'humidity') {
      defaultIndoorInput.rising = hasDehumidifier

      // @ts-ignore
      newTrigger.outputs = [
        ...(hasDehumidifier ? [dehu, ventDehuCompressor] : [hu]),
        ventDehuFan,
        airHandler,
      ].filter(x => x).map(x => x?.id)
    }

    if (pillar === 'ventilation') {
      // @ts-ignore
      newTrigger.outputs = [airHandler, ventDehuFan, mechVentilation, damper]
        .filter(x => x)
        .map(x => x?.id)

      if (damper && postal_code) {
        additionalInputs.push(defaultOutdoorAirInput(postal_code))
      }
    }

    if (pillar === 'filtration') {
      // @ts-ignore
      newTrigger.outputs = [airHandler, ventDehuFan, mechVentilation]
        .filter(x => x)
        .map(x => x?.id)
    }

    const newRule = {
      inputs: [defaultIndoorInput, ...additionalInputs],
      outputs: newTrigger.outputs.map(x => automation.outputs.find(y => y.id === x)).filter(x => x),
      trigger: newTrigger,
      id: triggerId,
      zone,
      automationId: automation.id,
      dwellingId,
      pillar,
    }

    // @ts-ignore
    createTempAndCanonRule(triggerId, newRule)
  }

  const hasBlower = airHandler || ventDehuFan

  if (pillar === 'humidity' && !dehu && !hu) return null
  if (pillar === 'ventilation' && !hasBlower) return null
  if (pillar === 'filtration' && !hasBlower) return null

  return (
    <AddNew onClick={onClickNewAutomation}>
      New {pillar} automation
    </AddNew>
  )
}

function Automations({ dwellingId, hideTitle = false }: { dwellingId?: number, hideTitle?: boolean }) {
  const classes = useClasses()
  const loading = useLoadingStore()

  if (!dwellingId || loading) return (
    <div className={classes.root}>
      {loading ? <Loading /> : (
        <Muted>No dwelling selected</Muted>
      )}
    </div>
  )

  return (
    <div className={classes.root}>
      <Box
        display='flex'
        justifyContent='space-between'
        alignItems={['flex-start', 'flex-start', 'center']}
        mb={4}
        flexDirection={['column', 'column', 'row']}
      >
        {hideTitle ? (
          null
        ) : (
          <Box>
            <Typography variant='h6'>
              Automations
            </Typography>
            <p style={{ marginTop: 0 }}><Muted>Create and adjust equipment automations in this zone</Muted></p>
          </Box>
        )}
        <HumidityUnitSelector />
      </Box>
      <div className={classes.gridContainer}>
        <Stack justify='flex-start'>
          <ColumnTitle>
            Humidity
          </ColumnTitle>
          <Pillar
            pillar='humidity'
            dwellingId={dwellingId}
          />
          <NewAutomation
            pillar='humidity'
            dwellingId={dwellingId}
          />
        </Stack>
        <Stack justify='flex-start'>
          <ColumnTitle>
            Ventilation
          </ColumnTitle>
          <Pillar
            pillar='ventilation'
            dwellingId={dwellingId}
          />
          <NewAutomation
            pillar='ventilation'
            dwellingId={dwellingId}
          />
        </Stack>
        <Stack justify='flex-start'>
          <ColumnTitle>
            Filtration
          </ColumnTitle>
          <Pillar
            pillar='filtration'
            dwellingId={dwellingId}
          />
          <NewAutomation
            pillar='filtration'
            dwellingId={dwellingId}
          />
        </Stack>
      </div>
    </div>
  )
}

export default Automations
