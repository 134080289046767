import {
  Box,
  Slider,
  Typography,
  withStyles,
} from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import Stack from 'ui/stack'
import Emphasis from './emphasis'
import { InputControlProps } from './input'
import Muted from './muted'
import {
  selectTempInput,
  setTemporaryRuleInput,
  useRulesStore,
} from './store'
import {
  displayUnit,
  humanizeMeasurement,
  isNumber,
  max,
  min,
} from './util'
import useConvertTemperatureUnits from 'utils/hooks/useConvertTemperatureUnits'
import GraphicRisingTrue from './humidity-rising-true.png'
import GraphicRisingFalse from './humidity-rising-false.png'
import { humanize } from 'utils/string-utils'
import { theme } from 'theme'
import { useAppSelector } from 'utils/hooks/reduxTypes'
import { getUserPreferences } from 'state-mngt/selectors/user-selectors'
import RuleTitle from './rule-title'

const marks = ({ min, max, unit }) => [
  {
    value: min,
    label: `${min}${unit}`,
  },
  {
    value: max,
    label: `${max}${unit}`,
  },
]

const IndoorHumiditySlider = withStyles({
  track: {
    backgroundColor: 'transparent !important',
  },
  rail: {
    opacity: 1,
    background: 'linear-gradient(to right,  #ff2f00 0%, #feb52d 15%, #0ddc0d 50%, #feb52d 85%, #ff2f00 100% )',
  },
  mark: {
    backgroundColor: 'transparent !important',
  },
})(Slider)

function Humidity({
  ruleId,
  inputId,
}: InputControlProps) {
  const [localValue, setLocalValue] = useState<number | undefined>() // store it locally just so it updates visually on change without rendering the whole tree
  const [localRange, setLocalRange] = useState<number[] | undefined>() // store it locally just so it updates visually on change without rendering the whole tree

  const input = useRulesStore(selectTempInput<any>(ruleId, inputId))
  const preferences = useAppSelector(getUserPreferences)

  const { format, display, convertedUnit } = useConvertTemperatureUnits(input)

  useEffect(() => {
    if (!input) return
    if (isNumber(input.start_set_point)) setLocalValue(display(input.start_set_point))
  }, [input?.start_set_point, display])

  useEffect(() => {
    if (isNumber(input?.high_end_start_set_point) && isNumber(input?.low_end_start_set_point)) {
      setLocalRange([
        display(input.low_end_start_set_point),
        display(input.high_end_start_set_point),
      ])
    }
  }, [
    input?.high_end_start_set_point,
    input?.low_end_start_set_point,
    display,
  ])

  const handleChangeCommitted = (event, value) => {
    if (Array.isArray(value)) {
      setTemporaryRuleInput(ruleId, input?.id, {
        touched: true,
        high_end_start_set_point: format(value[1]),
        low_end_start_set_point: format(value[0]),
      })
    } else {
      setTemporaryRuleInput(ruleId, input?.id, {
        touched: true,
        start_set_point: format(value),
      })
    }
  }

  if (!input) return null

  const onChange = (event, value) => {
    if (Array.isArray(value)) {
      if (Math.abs(value[0] - value[1]) < 2) return
      setLocalRange(value)
    } else {
      setLocalValue(value)
    }
  }

  const {
    triggered_inside_range,
    measurement,
    rising,
    type,
  } = input

  const _min = min(measurement, convertedUnit, rising)
  const _max = max(measurement, convertedUnit, rising)

  const _marks = marks({
    min: min(measurement, convertedUnit, rising),
    max: max(measurement, convertedUnit, rising),
    unit: displayUnit(convertedUnit || input.measurement),
  })

  const triggerAction = input.rising ? 'rises above' : 'drops below'
  const humanizedMeasurement = humanizeMeasurement(type, measurement)

  const renderDidactic = () => {
    if (localRange &&
      isNumber(localRange[0]) &&
      isNumber(localRange[1])) {
      if (triggered_inside_range) {
        return (
          <>
            <Emphasis>
              {humanizedMeasurement}
            </Emphasis>
            {` `}is within{` `}
            <Emphasis>
              {localRange[0]}
            </Emphasis>
            {` `}and{` `}
            <Emphasis>
              {localRange[1]}{displayUnit(convertedUnit || measurement)}
            </Emphasis>
          </>
        )
      }
      return (
        <>
          <Emphasis>
            {humanizedMeasurement}
          </Emphasis>
          {` `}is below{` `}
          <Emphasis>
            {localRange[0]}
          </Emphasis>
          {` `}or above{` `}
          <Emphasis>
            {localRange[1]}{displayUnit(convertedUnit || measurement)}
          </Emphasis>
        </>
      )
    }
    if (isNumber(localValue)) {
      return (
        <>
          <Emphasis>
            {humanizedMeasurement}
          </Emphasis>
          {` `} {triggerAction} {` `}
          <Emphasis>
            {localValue}{displayUnit(convertedUnit || measurement)}
          </Emphasis>
        </>
      )
    }
    return null
  }

  const renderChart = () => {
    const suffix = measurement === 'humidity' ?
      '%' :
      preferences.temperature_isFahrenheit ? '°F' : '°C'

    const src = rising ? GraphicRisingTrue : GraphicRisingFalse

    const deadbandFactor = measurement === 'humidity' ?
      3 :
      preferences.temperature_isFahrenheit ? 2 : 1

    return (
      <Box>
        <Box
          width='100%'
          display='flex'
          justifyContent={rising ? 'space-between' : 'space-around'}
        >
          <Box
            display='flex'
            flexDirection='column'
            alignItems='center'
          >
            <RuleTitle>
              Turn on at
            </RuleTitle>
            <Typography
              variant='h5'
              style={{ color: theme.palette.success.main }}
            >{localValue ? localValue + deadbandFactor : null}{suffix}</Typography>
          </Box>
          <Box
            display='flex'
            flexDirection='column'
            alignItems='center'
          >
            <RuleTitle>
              On for at least
            </RuleTitle>
            <Typography
              variant='h5'
            >10m</Typography>
          </Box>
          <Box
            display='flex'
            flexDirection='column'
            alignItems='center'
          >
            <RuleTitle>
              Turn off at
            </RuleTitle>
            <Typography
              variant='h5'
            >{localValue ? localValue - deadbandFactor : null}{suffix}</Typography>
          </Box>
        </Box>
        <Box mt={2}>
          <RuleTitle
            style={{ maxWidth: '128px' }}
          >{humanize(measurement)} levels</RuleTitle>
        </Box>
        <Box
          display='flex'
        >
          <Box
            display='flex'
            flexDirection='column'
            justifyContent='space-between'
            alignItems='center'
            flex='0 80px'
            pt='10px'
            pb='16px'
          >
            <Typography>
              {localValue ? localValue + deadbandFactor : null}{suffix}
            </Typography>
            <Typography>
              {localValue ? localValue : null}{suffix}
            </Typography>
            <Typography>
              {localValue ? localValue - deadbandFactor : null}{suffix}
            </Typography>
          </Box>
          <Box
            flex='1 400px'
            maxWidth='400px'
          >
            <img style={{ maxWidth: '100%' }} src={src} />
          </Box>
        </Box>
      </Box>
    )
  }

  return (
    <Stack>
      <Typography
        variant='body1'
      >
        <Muted>
          {renderDidactic()}
        </Muted>
      </Typography>
      {(isNumber(localValue) || localRange?.length) && (
        <IndoorHumiditySlider
          track={rising ? 'inverted' : 'normal'}
          value={localValue || localRange}
          min={_min}
          max={_max}
          marks={_marks}
          onChange={onChange}
          onChangeCommitted={handleChangeCommitted}
          aria-labelledby={input.type} />
      )}
      <Box>
        {renderChart()}
      </Box>
    </Stack>
  )
}

export {
  min,
  max,
}

export default Humidity
