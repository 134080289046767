import { UserPreferencesEnum } from 'utils/constants/preference-enums'
import { MemberRole, MemberTeam, MemberStatus } from 'utils/constants/member-enums'

export interface UserServiceCompanyInvite {
  service_company_id: number,
  team: MemberTeam,
  email: string,
}

export interface TeamMember {
  id: number;
  first_name?: string;
  last_name?: string;
  email: string;
  team: MemberTeam;
  status: MemberStatus;
  invite_code?: string;
  roles?: MemberRole[];
}

export interface User {
  id: number;
  email: string;
  beta: boolean;
  name?: string;
  first_name?: string;
  last_name?: string;
  phone?: string;
  picture?: string;
  tos_timestamp?: string;
  team?: MemberTeam;
  status?: MemberStatus;
  role?: MemberRole;
  roles?: MemberRole[]; // user for member table display
  pm_unit: UserPreferencesEnum.PM_MC | UserPreferencesEnum.PM_COUNT,
  voc_unit: UserPreferencesEnum.VOC_MC | UserPreferencesEnum.VOC_COUNT,
  radon_unit: UserPreferencesEnum.BQ | UserPreferencesEnum.PIC,
  temperature_unit: UserPreferencesEnum.CELSIUS | UserPreferencesEnum.FAHRENHEIT,
  pro_portal_demo_enabled: boolean;
  pro_portal_tour_enabled: boolean;
  pro_portal_tour_notification_shown: boolean;
}

export interface UserPreference {
  pm_isMc: boolean;
  voc_isMc: boolean;
  temperature_isFahrenheit: boolean;
  pro_portal_demo_enabled: boolean;
}

export interface UserRole {
  user_id: number;
  service_company_id: number;
  role: number;
}

export interface UserProfile {
  id: number;
  has_central_air: boolean;
  filter_change_frequency: number;
}

export enum UserDemoName {
  Demo1 = 'particle_problems',
  Demo2 = 'relative_humidity_horrors',
  Demo3 = 'voc_vexations',
}

export enum DemoHintElementName {
  Timeframe = 'timeframe',
  DataType = 'dataType',
  SecondDataType = 'secondDataType',
}

export interface DemoHintElement {
  name: DemoHintElementName;
  text: string;
}

export interface DemoHintElements {
  [key: string]: DemoHintElement | null;
}

export enum UserDemoModal {
  Intro = 'Intro',
  Choose = 'Choose',
  Outro = 'Outro',
}

export interface UserDemo {
  isActive: boolean;
  activeDemo: UserDemoName | null;
  activeStep: number | null;
  demoModal: UserDemoModal | null;
  completedDemos: UserDemoName[];
  isCompletedDemosLoaded: boolean;
  hintElements: DemoHintElements;
  preferableDemo: UserDemoName | null;
}
