import React, { useEffect, useState } from 'react'
import { useAppSelector } from 'utils/hooks/reduxTypes'
import { getUserPreferences } from 'state-mngt/selectors/user-selectors'
import { generateIndoorHumidityInput, genreateOutdoorHumidityInput } from './install/data'
import useMonitorInCurrentZone from '../charts/device-chart/useMonitorInCurrentZone'
import { useParams } from 'react-router-dom'
import { isHumidityInput, isIndoorHumidityInput } from './util'
import { selectFirstEquipmentAutomation, setAutomation, useAutomationsStore } from './store'
import { Box, BoxProps, FormControlLabel, Radio, RadioGroup, Typography } from '@material-ui/core'
import useCurrentZone from 'utils/hooks/useCurrentZone'
import RuleTitle from './rule-title'
import ConfirmHumidityDialog from './automations-editor/confirm-humidity-dialog'
import { Automation, AutomationInput, AutomationResponse } from 'types'
import httpService from 'state-mngt/services/data/http-service'
import { create } from 'zustand'
import useCurrentDwellingPostalCode from 'utils/hooks/useCurrentDwellingPostalCode'

const useHumidityMeasurementStore = create<string>(set => 'dew_point')

const HumidityUnitSelector = (props: BoxProps) => {
    const { dwellingId } = useParams()
    const zone = useCurrentZone()

    const automation = useAutomationsStore(selectFirstEquipmentAutomation(parseInt(dwellingId || ''), zone))

    const defaultMeasurement = automation?.inputs.find(input =>
        isHumidityInput(input) &&
        automation?.rules
            .filter(x => x.enabled)
            .flatMap(x => x.inputs)
            .includes(input.id))?.measurement

    const measurement = useHumidityMeasurementStore()

    const [dialogOpen, setDialogOpen] = useState(false)
    const [loading, setLoading] = useState(false)

    const preferences = useAppSelector(getUserPreferences)
    const monitor = useMonitorInCurrentZone()
    const postal_code = useCurrentDwellingPostalCode()

    useEffect(() => {
        if (defaultMeasurement) useHumidityMeasurementStore.setState(defaultMeasurement)
    }, [defaultMeasurement])

    useEffect(() => {

    }, [measurement])

    const handleUpdateHumidity = async () => {
        if (!measurement) return
        if (!automation?.id) return

        const newMeasurement = measurement === 'dew_point' ? 'humidity' : 'dew_point'

        const activeRules = automation?.rules
            .filter(x => x.enabled)

        const activeRulesWithHumidityInputs = activeRules
            ?.filter(x =>
                x.inputs.some(y =>
                    isHumidityInput(automation?.inputs.find(z =>
                        z.id === y))))

        const activeInputs = activeRulesWithHumidityInputs
            .flatMap(x => x.inputs)

        const oldInputs = automation.inputs.filter(x => activeInputs.includes(x.id)).filter(isHumidityInput)

        const newInputs: { [key: number]: AutomationInput } = oldInputs.reduce((prev, curr) => ({
            ...prev,
            [curr.id]: isIndoorHumidityInput(curr) ?
                generateIndoorHumidityInput(preferences)(newMeasurement)(monitor?.id) :
                genreateOutdoorHumidityInput(preferences)(newMeasurement)(postal_code)
        }), {})

        const updatedRules = activeRulesWithHumidityInputs.reduce((prev, curr) => ({
            ...prev,
            [curr.id]: {
                ...curr,
                inputs: curr.inputs.map(y => newInputs[y]?.id || y),
            },
        }), {})

        const updatedAutomation: Partial<Automation> = {
            inputs: [...automation.inputs, ...Object.values(newInputs)],
            rules: automation.rules.map(x => updatedRules[x.id] || x),
        }

        try {
            await httpService.post(`/automation/${automation.id}`, { ...automation, ...updatedAutomation })
            const r = await httpService.get<AutomationResponse>(`/automation/${automation.id}`)
            setAutomation(automation.id, r)
            setDialogOpen(false)
            useHumidityMeasurementStore.setState(newMeasurement)
        } catch (e) {
            console.error(e)
        }
    }

    const handleChange = (e, value) => {
        setDialogOpen(true)
    }

    const onClickYes = async () => {
        setLoading(true)
        await handleUpdateHumidity()
        setLoading(false)
    }

    if (!automation?.id) return null
    if (!measurement) return null

    return (
        <>
            <Box py={1} mb={2} {...props}>
                <RuleTitle>Preferred humidity unit</RuleTitle>
                <RadioGroup
                    row
                    value={measurement}
                    aria-label={'select-humidity-rule-input'}
                    name={'select-humidity-rule-unit'}
                    onChange={handleChange}
                >
                    <FormControlLabel
                        value='dew_point'
                        control={<Radio size='small' />}
                        label={
                            <Typography>
                                Dew point (°{preferences?.temperature_isFahrenheit ? 'F' : 'C'})
                            </Typography>
                        } />
                    <FormControlLabel
                        value='humidity'
                        control={<Radio size='small' />}
                        label={
                            <Typography>
                                Relative humidity (%)
                            </Typography>
                        } />
                </RadioGroup>
            </Box>
            <ConfirmHumidityDialog
                measurement={measurement === 'humidity' ? 'dew point' : 'humidity'}
                loading={loading}
                isOpen={dialogOpen}
                onClickCancel={() => setDialogOpen(false)}
                onClickYes={onClickYes}
            />
        </>
    )
}

export {
    useHumidityMeasurementStore,
}

export default HumidityUnitSelector
