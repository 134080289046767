import React from 'react'
import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from '@material-ui/core'
import Muted from './muted'

const InputSelector = ({ onChange, selectedInputMeasurement, options, disable = x => false, label = 'Measurement' }) => {
  return (
    <FormControl
      id={`input_select`}
      size='small'
      variant='outlined'
      style={{
        width: '100%',
      }}
    >
      <InputLabel
        id={`input_select-${label}`}>{label}</InputLabel>
      <Select
        id={`input_select-${label}`}
        name={`input_select-${label}`}
        labelId={`input_select-${label}`}
        label={label}
        value={selectedInputMeasurement}
        onChange={onChange}
      >
        <MenuItem
          disabled
          value={``}
        >
          Select...
        </MenuItem>
        {options.map(x => (
          <MenuItem
            key={x.value}
            disabled={disable(x.value)}
            value={x.value}
          >
            <Box display='flex' flexDirection='column'>
              {x.title}
              {x.subtitle ? (
                <Muted style={{ fontSize: '14px' }}>{x.subtitle}</Muted>
              ) : null}
            </Box>
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}

export default InputSelector
