import { RootState } from 'state-mngt/store'
import { ServiceCompanyRole } from 'state-mngt/models/serviceCompany'
import { UserPreferencesEnum } from 'utils/constants/preference-enums'
import {
  UserDemoName,
  DemoHintElementName,
  DemoHintElement,
  UserDemoModal,
} from 'state-mngt/models/user'
import { createSelector } from 'reselect'

export const selectIsLoadingUser = (state: RootState) => state.user.isLoadingUser
export const selectLoadingUserError = (state: RootState) => state.user.loadingUserError
export const selectUser = (state: RootState) => state.user.user
export const getUserId = (state: RootState): number | undefined => state.user.user?.id
export const selectUserRole = (state: RootState): ServiceCompanyRole | undefined => state.user.role

export const getUserDemoActiveDemo = (state: RootState): UserDemoName | null => state.user.demo.activeDemo
export const getUserDemoActiveStep = (state: RootState): number | null => state.user.demo.activeStep
export const getDemoModal = (state: RootState): UserDemoModal | null => state.user.demo.demoModal
export const isDemoModalOpen = (state: RootState): boolean => {
  if (state.user.demo.isActive) {
    return state.user.demo.activeDemo === null
  }
  return false
}
export const getUserCompletedDemos = (state: RootState): UserDemoName[] => state.user.demo.completedDemos
export const isUserCompletedDemosLoaded = (state: RootState): boolean => state.user.demo.isCompletedDemosLoaded
export const selectIsDemoDataEnabled = (state: RootState): boolean => !!state.user.user?.pro_portal_demo_enabled
export const selectIsProPortalTourEnabled = (state: RootState): boolean => !!state.user.user?.pro_portal_tour_enabled
export const selectIsProPortalTourNotificationShown = (state: RootState): boolean => {
  return !!state.user.user?.pro_portal_tour_notification_shown
}

export const getUserPreferences = createSelector(
  (state: RootState) => state.user.user,
  (user) => {
    if (!user) {
      return {
        temperature_isFahrenheit: true,
        pm_isMc: true,
        voc_isMc: true,
        radon_unit: 'picocuries',
        pro_portal_demo_enabled: true,
        pro_portal_tour_enabled: false,
      }
    }
    return {
      temperature_isFahrenheit: user.temperature_unit === UserPreferencesEnum.FAHRENHEIT,
      pm_isMc: user.pm_unit === UserPreferencesEnum.PM_MC,
      voc_isMc: user.voc_unit === UserPreferencesEnum.VOC_MC,
      radon_unit: user.radon_unit,
      pro_portal_demo_enabled: user.pro_portal_demo_enabled,
      pro_portal_tour_enabled: user.pro_portal_tour_enabled,
    }
  },
)

export const getHintElement = (state: RootState, name: DemoHintElementName): DemoHintElement | undefined | null =>
  state.user.demo.hintElements[name]
export const getPreferableDemo = (state: RootState) => state.user.demo.preferableDemo
