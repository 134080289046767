import React from 'react'
import {
  Backdrop,
  Button,
  Fade,
  FormControl,
  FormControlLabel,
  Modal,
  Radio,
  RadioGroup,
  Typography,
  createStyles,
  makeStyles,
} from "@material-ui/core"
import { useState } from "react"
import Emphasis from './emphasis'
import Stack from 'ui/stack'
import RuleTitle from './rule-title'
import Muted from './muted'
import ButtonSpinner from './button-spinner'
import { CloseReason } from '@material-ui/lab'

const useStyles = makeStyles(theme => createStyles({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: 'white',
    borderRadius: '11px',
  },
  modalContent: {
    padding: '16px 24px 24px 24px',
  },
  modalFooter: {
    // border: `1px solid ${theme.palette.charcoalLightTint}`,
    borderLeftWidth: 0,
    borderRightWidth: 0,
    borderBottomWidth: 0,
    padding: '0 24px 24px 24px',
    display: 'flex',
    justifyContent: 'space-between',
  },
  controls: {
  },
}))

function OverrideModal({
  equipmentIsTriggered,
  override,
  loading,
  isOpen,
  onClose,
}) {
  const classes = useStyles()
  const [time, setTime] = useState(60 * 60) // seconds

  const handleChangeTime = (event, value) => {
    setTime(parseInt(value))
  }

  const _onClose = (e, reason) => {
    if (reason === 'backdropClick' && loading) return null
    onClose()
  }

  const onClickOverride = async () => {
    const timeout = new Date(Date.now() + (time * 1000)).toISOString()
    override(timeout)
  }

  return (
    <Modal
      aria-labelledby="override"
      aria-describedby="control the automation override"
      className={classes.modal}
      open={isOpen}
      disableEscapeKeyDown={loading}
      onClose={_onClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={isOpen}>
        <div className={classes.paper}>
          <div className={classes.modalContent}>
            <Stack>
              <Stack spacing={0}>
                <Typography variant='subtitle1'>
                  Override this group of equipment?
                </Typography>
                <Typography
                  variant='body1'
                >
                  <Muted>
                    This equipment is currently <Emphasis>{equipmentIsTriggered ? 'on' : 'off'}</Emphasis>, overriding will turn it <Emphasis>{equipmentIsTriggered ? 'off' : 'on'}</Emphasis>.<br />
                    When the override ends the automation will continue as normal.
                  </Muted>
                </Typography>
              </Stack>
              <Stack
                spacing={0}
              >
                <FormControl component='fieldset'>
                  <RuleTitle>Duration</RuleTitle>
                  <RadioGroup
                    row
                    className={classes.controls}
                    aria-label="duration"
                    name="duration"
                    value={time}
                    onChange={handleChangeTime}>
                    <FormControlLabel value={60 * 60} control={<Radio />} label="1 hour" />
                    <FormControlLabel value={60 * 60 * 24} control={<Radio />} label="24 hours" />
                  </RadioGroup>
                </FormControl>
              </Stack>
            </Stack>
          </div>
          <div className={classes.modalFooter}>
            <Button
              disabled={loading}
              onClick={onClose}>Cancel</Button>
            <Button
              onClick={onClickOverride}
              disabled={loading}
              variant='contained'
              size='small'
              color='primary'>{loading ? <ButtonSpinner /> : <>Override</>}</Button>
          </div>
        </div>
      </Fade>
    </Modal>
  )
}

export default OverrideModal
