import { useParams } from "react-router-dom"
import useDwellingStore, { selectDetails } from "stores/dwelling"

function useCurrentDwellingPostalCode() {
    const { dwellingId } = useParams()
    const dwelling = useDwellingStore(selectDetails(parseInt(dwellingId || '')))
    return dwelling?.postal_code
}

export default useCurrentDwellingPostalCode
