import { Automation, AutomationResponse, AutomationRule } from "types"
import deepEqual from 'deep-equal'
import {
  selectAutomation,
  selectRule,
  setAutomation,
  tempKey,
  useAutomationsStore,
  useRulesStore,
} from "./store"
import httpService from "state-mngt/services/data/http-service"
import { tempId } from "./util"

/**
 * saving an automation requires creating new inputs
 * and outputs and saving those to the automation
 */
const mergeRule = (tempRule: AutomationRule, automation: Automation): Automation => {
  const ruleId = tempRule.id

  // these are inputs that already existed but have been changed
  const touchedInputs = tempRule.inputs.filter(x => {
    const canonInput = automation.inputs.find(y => y.id === x.id)
    if (!canonInput) return false
    return !deepEqual(x, canonInput)
  })

  const newInputs = touchedInputs.map(x => ({ ...x, id: tempId() }))
  const inputsToRemove = touchedInputs.map(x => x.id)

  const touchedOutputs = tempRule.outputs.filter(x => {
    const canonOutput = automation.outputs.find(y => y.id === x.id)
    if (!canonOutput) return false
    return !deepEqual(x, canonOutput)
  })

  const newOuputs = touchedOutputs.map(x => ({ ...x, id: tempId() }))
  const outputsToRemove = touchedOutputs.map(x => x.id)

  // changed inputs are replaced with new input instances
  const newInputsAndExistingCanonInputs = [
    ...newInputs,
    ...tempRule.inputs.filter(x => !inputsToRemove.includes(x.id)),
  ]

  const newOutputsAndExistingCanonOutputs = [
    ...newOuputs,
    ...tempRule.outputs.filter(x => !outputsToRemove.includes(x.id)),
  ]

  return {
    ...automation,
    rules: automation.rules.map((rule) => {
      if (rule.id === ruleId) return {
        ...rule,
        inputs: newInputsAndExistingCanonInputs.map(x => x.id),
        outputs: newOutputsAndExistingCanonOutputs.map(x => x.id),
      }
      return rule
    }),
    outputs: newOutputsAndExistingCanonOutputs,
    inputs: newInputsAndExistingCanonInputs,
  }
}

function useMergeTemporaryRuleIntoAutomation(ruleId) {
  const tempRule = useRulesStore(selectRule(tempKey(ruleId)))
  const automation = useAutomationsStore(selectAutomation(tempRule?.automationId))
  if (!tempRule || !automation) return null
  return mergeRule(tempRule, automation)
}

async function save(automation) {
  try {
    await httpService.post(`/automation/${automation.id}`, automation)
    const r = await httpService.get<AutomationResponse>(`/automation/${automation.id}`)
    setAutomation(automation.id, r)
    return { ...r, id: automation.id }
  } catch (e) {
    throw e
  }
}

/**
 * used for persisting an automation that has been
 * changed via a temporary rule. merges the temporary
 * rule into the automation, and then saves the changes
 */
function useSaveTemporaryRuleToAutomation(ruleId?: number) {
  if (!ruleId) return () => Promise.resolve()
  const mergedAutomation = useMergeTemporaryRuleIntoAutomation(ruleId)
  if (!mergedAutomation) return () => Promise.resolve()
  return (automationUpdates: Partial<Automation> = {}) => save({ ...automationUpdates, ...mergedAutomation })
}

export {
  useMergeTemporaryRuleIntoAutomation,
}

export default useSaveTemporaryRuleToAutomation
