export enum UserPreferencesEnum {
  CELSIUS = 'celsius',
  FAHRENHEIT = 'fahrenheit',
  PM_COUNT = 'pm_count',
  VOC_COUNT = 'voc_count',
  FEET = 'feet',
  METRES = 'metres',
  VOC_MC = 'voc_mc',
  PM_MC = 'pm_mc',
  PIC = 'picocuries',
  BQ = 'becquerels',
}
