import { useParams } from "react-router-dom"
import useCurrentZone from "./useCurrentZone"
import {
  selectFirstEquipmentAutomation,
  useAutomationsStore,
} from "features/customer-drill-down/equipment/store"

/**
 * returns equipment automations in the current zone
 */
function useEquipmentAutomation() {
  const zone = useCurrentZone()
  const { dwellingId } = useParams()
  const automation = useAutomationsStore(selectFirstEquipmentAutomation(parseInt(dwellingId || ''), zone))
  return automation
}

export default useEquipmentAutomation
