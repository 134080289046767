import * as React from 'react'
import {
    selectCanonicalEcosenseConditionInput,
    selectTempEcosenseConditionInput,
    selectTempRule,
    setTemporaryRule,
    useRulesStore,
} from "./store"
import InputSelector from './input-selector'
import AutomationInputControl from './input'
import { humanize } from 'utils/string-utils'
import AddNew from './install/add-new'
import { Box, IconButton } from '@material-ui/core'
import RuleTitle from './rule-title'
import InnerCard from './inner-card'
import { Close } from '@material-ui/icons'
import { getUserPreferences } from 'state-mngt/selectors/user-selectors'
import { useAppSelector } from 'utils/hooks/reduxTypes'
import { tempId } from './util'

const ecosenseVentilationMeasurements = [
    'radon',
]

const ecosenseInputOptions = ecosenseVentilationMeasurements.map(x => ({ value: x, title: `Indoor ${humanize(x)}` }))

const getDefaultMeasurementByPillar = (pillar) => {
    switch (pillar) {
        case 'humidity': return 'dew_point'
        case 'ventilation': return 'voc'
        case 'filtration': return 'pm25'
        default: return 'unknown'
    }
}

const defaultEcosenseInput = (serial_number, radon_unit) => ({
    id: tempId(),
    type: "ecosense_threshold",
    serial_number,
    measurement: "radon",
    rising: true,
    start_set_point: 2.7,
    stop_set_point: 2.4,
    set_point_unit: radon_unit,
    detection_duration: 300,
})

const EcosenseCondition = ({ ruleId, ecosenseDevice }) => {
    const originalInput = useRulesStore(selectCanonicalEcosenseConditionInput(ruleId))
    const tempInput = useRulesStore(selectTempEcosenseConditionInput(ruleId))
    const tempRule = useRulesStore(selectTempRule(ruleId))
    const pillar = tempRule?.pillar
    const isFirst = tempRule.inputs[0]?.id === tempInput?.id

    const preferences = useAppSelector(getUserPreferences)

    const onChange = (e) => {
        // there's no point allowing this to change because
        // there's only one measurement type for an ecosense device
    }

    const onClickAddNewInput = () => {
        if (originalInput) {
            const update = [
                ...tempRule.inputs,
                originalInput,
            ]

            setTemporaryRule(ruleId, {
                inputs: update,
                trigger: {
                    ...tempRule.trigger,
                    inputs: update.map(x => x.id),
                },
            })
        } else {
            const update = [
                ...tempRule.inputs,
                defaultEcosenseInput(ecosenseDevice.serial_number, preferences.radon_unit || 'picocuries'),
            ]

            setTemporaryRule(ruleId, {
                inputs: update,
                trigger: {
                    ...tempRule.trigger,
                    inputs: update.map(x => x.id),
                },
            })
        }
    }

    const onClickDeleteInput = () => {
        const update = tempRule.inputs.filter(x => (x.id !== tempInput.id))

        setTemporaryRule(ruleId, {
            inputs: update,
            trigger: {
                ...tempRule.trigger,
                inputs: update.map(x => x.id),
            },
        })
    }

    if (!tempRule) return null
    if (!pillar) return null

    if (!tempInput) return (
        <AddNew onClick={onClickAddNewInput}>
            New Ecosense condition
        </AddNew>
    )

    return (
        <>
            <InnerCard pt={1}>
                <Box
                    display='flex'
                    alignItems='center'
                    justifyContent='space-between'
                >
                    <RuleTitle>{isFirst ? 'When' : 'And'}</RuleTitle>
                    <Box ml={1}>
                        <IconButton
                            onClick={onClickDeleteInput}
                            size='small'
                        >
                            <Close color='error' />
                        </IconButton>
                    </Box>
                </Box>
                <Box mt={2}>
                    <InputSelector
                        options={ecosenseInputOptions.map(x => ({ ...x, subtitle: `Ecosense ${ecosenseDevice?.serial_number}` }))}
                        onChange={onChange}
                        selectedInputMeasurement={tempInput.measurement}
                        label='Indoor condition'
                    />
                </Box>
                <Box mt={2}>
                    <AutomationInputControl
                        ruleId={ruleId}
                        inputId={tempInput.id}
                    />
                </Box>
            </InnerCard>
        </>
    )
}

export {
    getDefaultMeasurementByPillar,
}

export default EcosenseCondition
