import * as React from 'react'
import {
    selectCanonicalIndoorConditionInput,
    selectTempIndoorConditionInput,
    selectTempRule,
    setTemporaryRule,
    useRulesStore,
} from "./store"
import InputSelector from './input-selector'
import AutomationInputControl from './input'
import { defaultIndoorInputs } from './install/data'
import AddNew from './install/add-new'
import { Box, IconButton } from '@material-ui/core'
import RuleTitle from './rule-title'
import InnerCard from './inner-card'
import { Close } from '@material-ui/icons'
import useMonitorInCurrentZone from '../charts/device-chart/useMonitorInCurrentZone'
import { getUserPreferences } from 'state-mngt/selectors/user-selectors'
import { useAppSelector } from 'utils/hooks/reduxTypes'
import { useHumidityMeasurementStore } from './humidity-unit-selector'

const indoorInputOptions = {
    'humidity': [{
        value: 'humidity',
        title: `Indoor humidity`,
    }, {
        value: 'dew_point',
        title: `Indoor dew point`,
    }],
    'ventilation': [{
        value: 'voc',
        title: `Indoor VOC`,
    }],
    'filtration': [{
        value: 'pm25',
        title: 'Indoor PM2.5'
    }],
}

const getDefaultMeasurementByPillar = (pillar) => {
    switch (pillar) {
        case 'humidity': return 'dew_point'
        case 'ventilation': return 'voc'
        case 'filtration': return 'pm25'
        default: return 'unknown'
    }
}

const IndoorCondition = ({ ruleId }) => {
    const originalInput = useRulesStore(selectCanonicalIndoorConditionInput(ruleId))
    const previousInput = React.useRef(originalInput)
    const tempInput = useRulesStore(selectTempIndoorConditionInput(ruleId))
    const tempRule = useRulesStore(selectTempRule(ruleId))
    const monitor = useMonitorInCurrentZone()
    const isFirst = tempRule.inputs[0]?.id === tempInput?.id
    const preferences = useAppSelector(getUserPreferences)
    const pillar = tempRule?.pillar

    const selectedHumidityMeasurement = useHumidityMeasurementStore()

    const defaults = {
        ...defaultIndoorInputs(preferences),
        ...(originalInput ? { [originalInput.measurement]: originalInput } : {}),
    }

    const updateTempRuleWithNewInput = (measurement, replace = true) => {
        const selectedInput = defaults[measurement]
        selectedInput.device_id = monitor?.id

        if (pillar === 'humidity') {
            const isDehumidifierRule = tempRule.outputs.map(output => output.equipment).map(equipment => equipment?.type).includes('dehumidifier') ||
                tempRule.outputs.map(output => output.equipment).map(equipment => equipment?.type).includes('ventilating_dehumidifier')
            selectedInput.rising = isDehumidifierRule
        }

        const prevInputIndex = tempRule.inputs.findIndex(x => x.id === previousInput.current.id)
        const update = replace ? tempRule.inputs.map((x, i) => i === prevInputIndex ? selectedInput : x) : [...tempRule.inputs, selectedInput]
        previousInput.current = selectedInput

        setTemporaryRule(ruleId, {
            inputs: update,
            trigger: {
                ...tempRule.trigger,
                inputs: update.map(x => x.id),
            },
        })
    }

    const onChange = (e) => {
        const { value } = e.target
        updateTempRuleWithNewInput(value)
    }

    const onClickAddNewInput = () => {
        if (originalInput) return updateTempRuleWithNewInput(originalInput.measurement, false)
        const measurement = getDefaultMeasurementByPillar(pillar)
        updateTempRuleWithNewInput(measurement, false)
    }

    const onClickDeleteInput = () => {
        const update = tempRule.inputs.filter(x => (x.id !== originalInput.id) && (x.id !== previousInput.current.id))

        setTemporaryRule(ruleId, {
            inputs: update,
            trigger: {
                ...tempRule.trigger,
                inputs: update.map(x => x.id),
            },
        })
    }

    if (!tempRule) return null
    if (!pillar) return null

    if (!tempInput) return (
        <AddNew onClick={onClickAddNewInput}>
            New indoor condition
        </AddNew>
    )

    const options = indoorInputOptions[pillar]
        .filter(x => pillar === 'humidity' ? x.value === selectedHumidityMeasurement : true)
        .map(x => ({ ...x, subtitle: `HAVEN Monitor ${monitor?.plastic_serial_number}` }))

    return (
        <>
            <InnerCard pt={1}>
                <Box
                    display='flex'
                    alignItems='center'
                    justifyContent='space-between'
                >
                    <RuleTitle>{isFirst ? 'When' : 'And'}</RuleTitle>
                    <Box ml={1}>
                        <IconButton
                            onClick={onClickDeleteInput}
                            size='small'
                        >
                            <Close color='error' />
                        </IconButton>
                    </Box>
                </Box>
                <Box mt={2}>
                    <InputSelector
                        options={options}
                        onChange={onChange}
                        selectedInputMeasurement={tempInput.measurement}
                        label='Indoor condition'
                    />
                </Box>
                <Box mt={2}>
                    <AutomationInputControl
                        ruleId={ruleId}
                        inputId={tempInput.id}
                    />
                </Box>
            </InnerCard>
        </>
    )
}

export {
    getDefaultMeasurementByPillar,
}

export default IndoorCondition
