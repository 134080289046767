import React from 'react'
import { Box, BoxProps } from "@material-ui/core"
import { theme } from "theme"

function InnerCard(props: BoxProps) {
  return (
    <Box
      padding='16px'
      borderRadius='10px'
      border={`1px solid ${theme.palette.charcoalLightTint}`}
      borderColor='charcoalLightTint'
      {...props}
    />
  )
}

export default InnerCard
