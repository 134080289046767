import { makeStyles } from "@material-ui/core"

const useClasses = makeStyles(theme => ({
  root: {
    minHeight: 'calc(100vh - 252.5px)',
    backgroundColor: theme.palette.charcoalLightestTint,
  },
  gridContainer: {
    display: 'grid',
    gridTemplateColumns: 'repeat(3, 1fr)',
    gridGap: '24px',
    '@media (max-width: 1024px)': {
      gridTemplateColumns: 'repeat(1, 1fr)',
    },
    '@media (min-width: 1025px) and (max-width: 1344px)': {
      gridTemplateColumns: 'repeat(2, 1fr)',
    },
    alignItems: 'flex-start',
  },
  tabPanel: {
    minHeight: '100%',
    backgroundColor: theme.palette.charcoalLightestTint,
    padding: '32px !important',
    margin: 0,
  },
  ruleContainer: {
    flexDirection: 'column',
  },
  ruleTitle: {
    color: '#7A8D98',
    fontSize: 12,
  },
  rule: {
    padding: 16,
    paddingTop: 8,
    boxShadow: 'none',
    borderRadius: '13px',
  },
  ruleNoPadding: {
    boxShadow: 'none',
    borderRadius: '13px',
  },
  padding: {
    padding: 16,
    paddingTop: 8,
  },
  card: {
    background: 'white',
    borderRadius: '6px',
    padding: '16px',
    paddingLeft: '24px',
    paddingRight: '24px',
  },
  borderCard: {
    border: `1px solid ${theme.palette.charcoalLightTint}`,
    borderRadius: '6px',
    padding: '16px',
    // paddingLeft: '24px',
    // paddingRight: '24px',
  },
  twoColumnGrid: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    columnGap: '8px',
    rowGap: '16px',
  },
}))

export default useClasses
