import * as React from 'react'
import { useState } from "react"
import {
    displayUnit,
    humanizeMeasurement,
    outdoorMax,
    outdoorMin,
} from "./util"
import Stack from "ui/stack"
import { Slider, Typography } from "@material-ui/core"
import { setTemporaryRuleInput } from './store'
import Muted from './muted'
import Emphasis from './emphasis'
import useConvertTemperatureUnits from 'utils/hooks/useConvertTemperatureUnits'

const marks = ({ min, max, unit }) => [
    {
        value: min,
        label: `${min}${unit}`,
    },
    {
        value: max,
        label: `${max}${unit}`,
    },
]

function OutdoorWeatherRange({ ruleId, input }) {
    const [localRange, setLocalRange] = useState<number[]>([])

    const {
        measurement,
        rising,
        type,
        high_end_start_set_point,
        low_end_start_set_point,
    } = input

    const { format, display, convertedUnit } = useConvertTemperatureUnits(input)

    React.useEffect(() => {
        if (high_end_start_set_point && low_end_start_set_point) {
            setLocalRange([display(low_end_start_set_point), display(high_end_start_set_point)])
        }
    }, [
        display,
        high_end_start_set_point,
        low_end_start_set_point,
    ])

    const _min = outdoorMin(measurement, convertedUnit, rising)
    const _max = outdoorMax(measurement, convertedUnit, rising)

    const _marks = marks({
        min: outdoorMin(measurement, convertedUnit, rising),
        max: outdoorMax(measurement, convertedUnit, rising),
        unit: displayUnit(convertedUnit || input.measurement),
    })

    const onChange = (event, value) => {
        if (Math.abs(value[0] - value[1]) < 2) return
        setLocalRange(value)
    }

    const handleChangeCommitted = (event, value) => {
        setTemporaryRuleInput(ruleId, input?.id, {
            high_end_start_set_point: format(value[1]),
            low_end_start_set_point: format(value[0]),
        })
    }

    const humanizedMeasurement = humanizeMeasurement(type, measurement)

    return (
        <Stack>
            <Typography
                variant='body1'
            >
                <Muted>
                    <Emphasis>
                        {humanizedMeasurement}
                    </Emphasis>
                    {` `}is within{` `}
                    <Emphasis>
                        {localRange[0]}
                    </Emphasis>
                    {` `}and{` `}
                    <Emphasis>
                        {localRange[1]}{displayUnit(convertedUnit || input.measurement)}
                    </Emphasis>
                </Muted>
            </Typography>
            <Slider
                track={rising ? 'inverted' : 'normal'}
                value={localRange}
                min={_min}
                max={_max}
                marks={_marks}
                onChange={onChange}
                onChangeCommitted={handleChangeCommitted}
                aria-labelledby={input.type} />
        </Stack>
    )
}

export default OutdoorWeatherRange
