import * as React from 'react'
import {
    selectTempOutdoorConditionInputs,
    selectTempRule,
    setTemporaryRule,
    useRulesStore,
} from "./store"
import InputSelector from './input-selector'
import AutomationInputControl from './input'
import {
    defaultOutdoorAirInput,
    defaultOutdoorDewPointRangeInput,
    defaultOutdoorHumidityRangeInput,
    defaultOutdoorTemperatureRangeInput,
} from './install/data'
import AddNew from './install/add-new'
import { Box, IconButton } from '@material-ui/core'
import RuleTitle from './rule-title'
import InnerCard from './inner-card'
import { Close } from '@material-ui/icons'
import { getUserPreferences } from 'state-mngt/selectors/user-selectors'
import { useAppSelector } from 'utils/hooks/reduxTypes'
import { Measurement, UserPreferences } from 'types'
import useDwellingStore, { selectDetails } from 'stores/dwelling'
import { useParams } from 'react-router-dom'
import { isIndoorHumidityInput, isOutdoorConditionInput, isOutdoorHumidityInput } from './util'
import { useHumidityMeasurementStore } from './humidity-unit-selector'

const outdoorMeasurements = [
    { value: 'humidity' as Measurement, title: 'Outdoor humidity', subtitle: 'Outdoor weather data' },
    { value: 'dew_point' as Measurement, title: 'Outdoor dew point', subtitle: 'Outdoor weather data' },
    { value: 'temperature' as Measurement, title: 'Outdoor temperature', subtitle: 'Outdoor weather data' },
    { value: 'epa_aqi' as Measurement, title: 'Outdoor air quality index', subtitle: 'Outdoor weather data' },
]

const defaultOutdoorInputsByMeasurement = ({ temperature_isFahrenheit }: UserPreferences, postalCode?: string) => ({
    'humidity': defaultOutdoorHumidityRangeInput(postalCode),
    'dew_point': defaultOutdoorDewPointRangeInput(postalCode, temperature_isFahrenheit),
    'temperature': defaultOutdoorTemperatureRangeInput(postalCode, temperature_isFahrenheit),
    'epa_aqi': defaultOutdoorAirInput(postalCode),
})

const OutdoorConditions = ({ ruleId }) => {
    const outdoorConditionInputs = useRulesStore(selectTempOutdoorConditionInputs(ruleId))
    const tempRule = useRulesStore(selectTempRule(ruleId))
    const indoorInput = tempRule.inputs.find(isIndoorHumidityInput)

    const { dwellingId } = useParams()
    const dwelling = useDwellingStore(selectDetails(parseInt(dwellingId || '')))
    const preferences = useAppSelector(getUserPreferences)
    const usedMeasurements = outdoorConditionInputs.map(x => x.measurement)

    const measurementPreference = useHumidityMeasurementStore()

    const hasDewPoint = usedMeasurements.includes('dew_point')
    const hasHumidity = usedMeasurements.includes('humidity')

    const available = outdoorMeasurements
        .map(x => x.value)
        .filter(x => !usedMeasurements.includes(x))
        .filter(x => hasDewPoint ? x !== 'humidity' : true)
        .filter(x => hasHumidity ? x !== 'dew_point' : true)
        .filter(x => x === 'humidity' ? (!measurementPreference || measurementPreference === 'humidity') : true)
        .filter(x => x === 'dew_point' ? (!measurementPreference || measurementPreference === 'dew_point') : true)

    const onClickAddNew = () => {
        if (!available.length) return console.warn('No measurements left to create an input for')
        if (!dwelling?.postal_code) return console.warn('Post code is required for outdor condition')

        let nextMeasurement: Measurement | undefined = available[0]
        if (indoorInput?.measurement) nextMeasurement = available.find(x => x === indoorInput.measurement) || available[0]

        const defaultInput = defaultOutdoorInputsByMeasurement(preferences, dwelling.postal_code)[nextMeasurement]
        const update = [...tempRule.inputs, defaultInput]

        setTemporaryRule(ruleId, {
            inputs: update,
            trigger: {
                ...tempRule.trigger,
                inputs: update.map(x => x.id),
            },
        })
    }

    return (
        <>
            {
                outdoorConditionInputs.map((input, index) => (
                    <Box mb={2} key={input.id}>
                        <OutdoorCondition
                            preferences={preferences}
                            postalCode={dwelling?.postal_code}
                            tempInput={input}
                            ruleId={ruleId}
                        />
                    </Box>
                ))
            }
            {Boolean(available.length) && (
                <AddNew onClick={onClickAddNew}>
                    New outdoor condition
                </AddNew>
            )}
        </>
    )
}

const OutdoorCondition = ({ ruleId, postalCode, tempInput, preferences }) => {
    const tempRule = useRulesStore(selectTempRule(ruleId))
    const existingOutdoorConditions = tempRule.inputs.filter(isOutdoorConditionInput)
    const previousInput = React.useRef(tempInput)
    const isFirst = tempRule.inputs[0].id === tempInput.id
    const pillar = tempRule?.pillar

    const usedMeasurements = existingOutdoorConditions.map(x => x.measurement)

    const hasDewPoint = usedMeasurements.includes('dew_point')
    const hasHumidity = usedMeasurements.includes('humidity')

    const selectedHumidityMeasurement = useHumidityMeasurementStore()

    const _defaults = {
        ...defaultOutdoorInputsByMeasurement(preferences, postalCode),
        [tempInput.measurement]: tempInput,
    }

    const updateTempRuleWithNewInput = (measurement, replace = true) => {
        const selectedInput = _defaults[measurement]
        const prevInputIndex = tempRule.inputs.findIndex(x => x.id === previousInput.current.id)
        let update = replace ? tempRule.inputs.map((x, i) => i === prevInputIndex ? selectedInput : x) : [...tempRule.inputs, selectedInput]
        previousInput.current = selectedInput

        if (hasDewPoint && measurement === 'humidity') update = update.filter(x => x.measurement !== 'dew_point')
        if (hasHumidity && measurement === 'dew_point') update = update.filter(x => x.measurement !== 'humidity')

        setTemporaryRule(ruleId, {
            inputs: update,
            trigger: {
                ...tempRule.trigger,
                inputs: update.map(x => x.id),
            },
        })
    }

    const onChange = (e) => {
        const { value } = e.target
        updateTempRuleWithNewInput(value)
    }

    const onClickDeleteInput = () => {
        const update = tempRule.inputs.filter(x => (x.id !== tempInput.id) && (x.id !== previousInput.current.id))

        setTemporaryRule(ruleId, {
            inputs: update,
            trigger: {
                ...tempRule.trigger,
                inputs: update.map(x => x.id),
            },
        })
    }

    if (!tempRule) return null
    if (!pillar) return null

    return (
        <>
            <InnerCard pt={1}>
                <Box
                    display='flex'
                    alignItems='center'
                    justifyContent='space-between'
                >
                    <RuleTitle>{isFirst ? 'When' : 'And'}</RuleTitle>
                    <Box ml={1}>
                        <IconButton
                            onClick={onClickDeleteInput}
                            size='small'
                        >
                            <Close color='error' />
                        </IconButton>
                    </Box>
                </Box>
                <Box mt={2}>
                    <InputSelector
                        options={outdoorMeasurements.filter(x => {
                            if (x.value === 'humidity' && selectedHumidityMeasurement === 'dew_point') return false
                            if (x.value === 'dew_point' && selectedHumidityMeasurement === 'humidity') return false
                            return true
                        })}
                        disable={x => {
                            return existingOutdoorConditions.map(x => x.measurement).includes(x)
                        }}
                        onChange={onChange}
                        selectedInputMeasurement={tempInput.measurement}
                        label='Outdoor condition'
                    />
                </Box>
                <Box mt={2}>
                    <AutomationInputControl
                        ruleId={ruleId}
                        inputId={tempInput.id}
                    />
                </Box>
            </InnerCard>
        </>
    )
}

export {
    defaultOutdoorInputsByMeasurement,
}

export default OutdoorConditions
