import {
    formatForStore,
    selectFirstEquipmentAutomation,
    setAutomation,
    useAutomationsStore,
    useEquipmentStore,
    useLogsStore,
    useRulesStore,
} from "features/customer-drill-down/equipment/store"
import { useEffect } from "react"
import { useParams } from "react-router-dom"
import useCurrentZone from "./useCurrentZone"
import { automationsToRules } from "features/customer-drill-down/equipment/util"

const mapEquipmentComponent = (equipment, equipment_component) => {
    if (!equipment) return null
    if (equipment.type !== 'ventilating_dehumidifier') return equipment
    return {
        ...equipment,
        type: equipment_component === 0 ? 'ventilating_dehumidifier_compressor' : 'ventilating_dehumidifier_fan',
    }
}

function useTieOutputToEquipment() {
    const logs = useLogsStore()
    const { dwellingId } = useParams()
    const zone = useCurrentZone()

    const equipmentAutomation = useAutomationsStore(selectFirstEquipmentAutomation(
        parseInt(dwellingId || ''),
        zone,
    ))
    const interlocks = equipmentAutomation?.interlocks || []
    const outputs = equipmentAutomation?.outputs || []
    const rules = equipmentAutomation?.rules || []

    const equipment = useEquipmentStore()

    useEffect(() => {
        const _logs = Object.values(logs || {})
        if (!outputs?.length) return

        const newOutputs = outputs.map(output => {
            const logsPerOutput = _logs
                .filter(log =>
                    log.rules.some(rule => rule.outputs.includes(output.id)))

            const currently_triggered = !Boolean(logsPerOutput.slice(-1)[0]?.start_timestamp && logsPerOutput.slice(-1)[0]?.stop_timestamp)
            const interlocked_blowers = interlocks.filter(x =>
                (x.primary_output === output.id)
            ).map(x => x.secondary_output)
            const interlocked_non_blowers = interlocks.filter(x =>
                (x.secondary_output === output.id)
            ).map(x => x.primary_output)

            return {
                ...output,
                currently_triggered,
                interlocked_non_blowers,
                interlocked_blowers,
                equipment: mapEquipmentComponent(equipment[output.equipment_id], output.equipment_component),
            }
        })

        const newRules = rules.map(rule => {
            const logsPerRule = _logs
                .filter(log =>
                    log.rules.some(_rule => _rule.id === rule.id))

            const hasStopTimestamp = Boolean(logsPerRule.slice(-1)[0]?.stop_timestamp)
            const currently_triggered = !hasStopTimestamp

            return { ...rule, currently_triggered }
        })

        if (equipmentAutomation) {
            const updatedAutomation = {
                ...equipmentAutomation,
                outputs: newOutputs,
                rules: newRules,
            }

            setAutomation(equipmentAutomation.id, updatedAutomation)
            const rules = automationsToRules([updatedAutomation])
            useRulesStore.setState(formatForStore(rules))
        }
    }, [
        equipmentAutomation?.id,
        JSON.stringify(equipment),
        JSON.stringify(logs),
        JSON.stringify(outputs),
    ])
}

export default useTieOutputToEquipment
