import { useEffect, useState } from 'react'
import { getUserPreferences } from 'state-mngt/selectors/user-selectors'
import { SetPoint } from 'types'
import { useAppSelector } from 'utils/hooks/reduxTypes'

const celsiusToFahrenheit = () => (value: number) => Math.round((value * 9 / 5) + 32)
const fahrenheitToCelsius = () => (value: number) => Math.round((value - 32) * 5 / 9)
const noop = () => x => x

// utility for displaying temperature values
// according to the user's preferences
// it returns functions that you pass the temperature
// values to
const useConvertTemperatureUnits = (input) => {
  const [convertedUnit, setConvertedUnit] = useState<SetPoint | null>(input?.set_point_unit || null)
  const { temperature_isFahrenheit } = useAppSelector(getUserPreferences)

  // convert a number to be correct format for display
  const [display, setDisplay] = useState<(x: number) => number>(noop)
  // convert a number to be correct format for data
  const [format, setFormat] = useState<(x: number) => number>(noop)

  useEffect(() => {
    if (!input) return
    if (typeof temperature_isFahrenheit !== 'boolean') return

    if (input.measurement === 'temperature' || input.measurement === 'dew_point') {
      if (temperature_isFahrenheit) {
        if (input.set_point_unit === 'fahrenheit') {
          setDisplay(noop)
          setFormat(noop)
          setConvertedUnit('fahrenheit')
        }
        if (input.set_point_unit === 'celsius') {
          setDisplay(celsiusToFahrenheit)
          setFormat(fahrenheitToCelsius)
          setConvertedUnit('fahrenheit')
        }
      }

      if (temperature_isFahrenheit === false) {
        if (input.set_point_unit === 'fahrenheit') {
          setDisplay(fahrenheitToCelsius)
          setFormat(celsiusToFahrenheit)
          setConvertedUnit('celsius')
        }
        if (input.set_point_unit === 'celsius') {
          setDisplay(noop)
          setFormat(noop)
          setConvertedUnit('celsius')
        }
      }
    } else if (input.measurement === 'humidity') {
      setDisplay(noop)
      setFormat(noop)
      setConvertedUnit(null)
    }

  }, [
    input?.measurement,
    input?.id,
    input?.start_set_point,
    input?.high_end_start_set_point,
    input?.low_end_start_set_point,
    temperature_isFahrenheit,
  ])

  return {
    convertedUnit,
    display,
    format,
  }
}

export {
  noop,
}

export default useConvertTemperatureUnits
