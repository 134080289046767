import React, { useState } from 'react'
import Rule from './rule'
import StaticRule from './static-rule'
import AutomationRuleCard from './automation-rule-card'
import { AutomationRule } from 'types'

const RuleWrapper = ({ rule }: { rule: AutomationRule }) => {
  const [editing, setEditing] = useState(rule.id < 0) // less than 0 means it's not in the database
  const [height, setHeight] = useState(0)

  return (
    <AutomationRuleCard style={{
      height,
      transition: 'height .5s',
      boxShadow: rule.id < 0 ? '0px 0px 12px rgba(0,0,0,0.15)' : 'none',
    }}>
      {editing ? (
        <Rule
          setHeight={setHeight}
          setEditing={setEditing}
          ruleId={rule.id}
        />
      ) : (
        <StaticRule
          setHeight={setHeight}
          ruleId={rule.id}
          handleClickEdit={() => setEditing(true)} />
      )}
    </AutomationRuleCard>
  )
}

export default RuleWrapper
