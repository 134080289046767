import React from 'react'
import { Box, Button, Typography } from "@material-ui/core"
import Stack from "ui/stack"
import Error from './error'
import ButtonSpinner from './button-spinner'

function Footer({
  error,
  validationError = '',
  touched,
  loading,
  success,
  handleSave,
  handleCancel,
  handleClose = null as any,
}) {
  return (
    <>
      {
        validationError && (
          <Box textAlign='right' mb={1}>
            <Typography variant='body2'>
              <Error>
                {validationError}
              </Error>
            </Typography>
          </Box>
        )
      }
      {
        error && (
          <Box textAlign='right' mb={1}>
            <Typography variant='body2'>
              <Error>
                {error}
              </Error>
            </Typography>
          </Box>
        )
      }
      <Stack direction='row'>
        {!touched && handleClose ?
          (
            <Button
              disabled={loading}
              onClick={handleClose}
            >Close</Button>
          ) : (
            <Button
              disabled={loading || !touched}
              onClick={handleCancel}
            >Cancel</Button>
          )}
        <Stack spacing={1} direction='row'>
          {success && (
            <Typography variant='body2' color='primary'>
              {success}
            </Typography>
          )}
          <Button
            disabled={Boolean(!touched || loading || validationError)}
            onClick={handleSave}
            size='small'
            variant='contained'
            color='primary'
          >
            {loading ?
              <ButtonSpinner /> :
              touched ? 'Save' : 'Saved'
            }
          </Button>
        </Stack>
      </Stack>
    </>

  )
}

export default Footer
