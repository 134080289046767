import React, { useEffect } from 'react'
import {
  Box,
  styled,
} from "@material-ui/core"
import { useState } from "react"
import httpService from 'state-mngt/services/data/http-service'
import {
  selectAutomation,
  selectTempRule,
  setAutomation,
  useAutomationsStore,
  useRulesStore,
} from './store'
import OverrideModal from './override-modal'
import differenceInHours from "date-fns/differenceInHours"
import differenceInMinutes from "date-fns/differenceInMinutes"
import differenceInSeconds from "date-fns/differenceInSeconds"
import differenceInDays from "date-fns/differenceInDays"
import { Automation } from 'types'
import ButtonSpinner from './button-spinner'
import RuleTitle from './rule-title'
import Switch from 'ui/switch'

const aboveZero = x => Math.round(Math.max(0, x))

const _format = (end, start) => {
  const days = differenceInDays(end, start)
  const hours = aboveZero(differenceInHours(end, start) % 24)
  const minutes = aboveZero(differenceInMinutes(end, start) % 60)
  const seconds = aboveZero(differenceInSeconds(end, start) % 60)

  return `${days ? `${days}d ` : ''}${hours ? `${hours}h ` : ''}${minutes ? `${minutes}m ` : ''}${seconds ? `${seconds}s ` : ''}`
}

const useTime = (time, cancel) => {
  const [duration, setDuration] = useState<string | null>(null)

  useEffect(() => {
    if (!time) return

    const interval = setInterval(() => {
      if (time - Date.now() <= 0) {
        clearInterval(interval)
        cancel()
        return setDuration(null)
      }
      setDuration(_format(time, Date.now()))
    }, 1000)

    return () => clearInterval(interval)
  }, [time])

  return duration
}

const now = new Date()

const Link = styled('a')(props => ({
  fontSize: '0.75rem',
  cursor: 'pointer',
  color: props.theme.palette.blewStream,
  '&:hover': {
    textDecoration: 'underline',
    // color: props.theme.palette.blewStream,
  },
}))

function OverrideSwitch({
  ruleId,
}) {
  const [open, setOpen] = useState(false)
  const [switchLoading, setSwitchLoading] = useState(false)
  const [loading, setLoading] = useState(false)

  const rule = useRulesStore(selectTempRule(ruleId))
  const automation = useAutomationsStore(selectAutomation(rule?.automationId))

  const ruleIsRunning = Boolean((rule.trigger.override_timeout && rule.trigger.override_on) || rule.trigger.currently_triggered)

  if (!rule) return null

  const {
    override_timeout,
    currently_triggered,
  } = rule.trigger

  // @ts-ignore
  const overrideOn = Boolean(override_timeout) && (new Date(override_timeout) > now)

  const save = async (data: Partial<Automation>) => {
    try {
      await httpService.post(`/automation/${rule.automationId}`, data)
      setAutomation(rule.automationId, data)
    } catch (e) {
      console.error(e)
    }
  }

  // save and then update state
  const saveAndUpdate = async (override_timeout: string | null) => {
    const data = {
      ...automation,
      rules: automation.rules.map(rule => rule.id === ruleId ? ({
        ...rule,
        override_timeout,
        override_on: !currently_triggered,
        currently_triggered: !currently_triggered,
      }) : rule),
    }

    await save(data)
  }

  const override = async (override_timeout) => {
    setLoading(true)
    await saveAndUpdate(override_timeout)
    setOpen(false)
    setLoading(false)
    setSwitchLoading(false)
  }

  const cancel = async () => {
    setLoading(true)
    const data = {
      ...automation,
      rules: automation.rules.map(rule => rule.id === ruleId ? ({
        ...rule,
        override_timeout: null,
        currently_triggered: !currently_triggered,
      }) : rule),
    }
    await save(data)
    setOpen(false)
    setLoading(false)
    setSwitchLoading(false)
  }

  const timer = useTime(
    override_timeout ? new Date(override_timeout).getTime() : null,
    cancel,
  )

  const onChangeOverride = () => {
    setSwitchLoading(true)
    setOpen(true)
  }

  const onCloseModal = () => {
    setOpen(false)
    setLoading(false)
    setSwitchLoading(false)
  }

  if (overrideOn) return (
    <>
      {loading ? (
        <Box width='28px' display='flex' justifyContent='center'>
          <ButtonSpinner />
        </Box>
      ) : (
        <Link onClick={cancel}>Override {timer}</Link>
      )}
    </>
  )

  return (
    <Box display='flex' gridGap='4px' alignItems='center'>
      <RuleTitle>Override</RuleTitle>
      {switchLoading ?
        (
          <Box width='28px' display='flex' justifyContent='center'>
            <ButtonSpinner />
          </Box>
        ) :
        (
          <Switch
            checked={ruleIsRunning}
            onChange={onChangeOverride}
          />
        )}
      <OverrideModal
        isOpen={open}
        loading={loading}
        equipmentIsTriggered={currently_triggered}
        onClose={onCloseModal}
        override={override}
      />
    </Box>
  )
}

export default OverrideSwitch
